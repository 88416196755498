import ChartCore from '@/components/ChartCore';
import { useCreation } from 'ahooks';
import { Spin } from '@antd';
import { isEmpty } from 'lodash';
import { dict } from '@/hooks/useChangeLocale';

const Chart = ({ data, loading }: { data: AnyObject[]; loading: boolean }) => {
  const sum = data.reduce((a, b) => a + b.value, 0) || 1;
  const options = useCreation(
    () => ({
      tooltip: {
        trigger: 'axis',
        formatter: function (params) {
          let tipStr = `${params[0].name}<br/>`;
          params.forEach(function (item) {
            const percent = `${((item.value / sum) * 100).toFixed(2)}%`; // 计算百分比
            tipStr += ` ${item.value}${dict('STATIST_UNIT_REN')} (${percent})`;
          });
          return tipStr;
        },
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {},
      xAxis: {
        type: 'value',
      },
      yAxis: {
        type: 'category',
        offset: 10,
        boundaryGap: ['20%', '20%'],
        inverse: true,
        data: data.map((item) => item.name),
      },
      series: [
        {
          type: 'bar',
          label: {
            show: true,
            formatter: (params) => `${((params.value / sum) * 100).toFixed(2)}%`,

            offset: [15, 0],
          },

          realtimeSort: true,
          data: data.map((item) => item.value),
        },
      ],
    }),
    [data],
  );

  return (
    <Spin spinning={loading}>
      <ChartCore option={options} empty={isEmpty(data)} style={{ height: 300 }} notMerge />
    </Spin>
  );
};

export default Chart;
