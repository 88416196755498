import ChartCore from '@/components/ChartCore';
import { DATE_FORMAT_STR, DATE_MINI_FORMAT_STR, DateUtils } from '@/utils/dateUtil';
import { Spin } from '@antd';
import { useCreation, useRequest } from 'ahooks';
import { useAppContext } from '@/contexts/AppContext';
import { getLiveStreamTrend } from '@/services/live_stream/all';
import { MEDIA_KEY_ENUM } from '@/constants/const';
import { dict } from '@/hooks/useChangeLocale';
import { WxLiveStreamDetailTrendVo } from '@/services/live_stream/wx/type';
import { isEmpty } from 'lodash';

const getOption = (data, labels, indicatorOptions, format = 'HH:mm', defaultShowCount = 3) => {
  return {
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      left: 20,
      data: indicatorOptions.map((v) => v.label),
      selected: indicatorOptions
        .map((v) => v.label)
        .reduce((acc, cur, index) => {
          acc[cur] = index < defaultShowCount;
          return acc;
        }, {}),
    },
    grid: {
      top: 50,
      left: 20,
      right: 20,
      bottom: 30,
    },
    dataZoom: data.length > 10 ? [{ maxValueSpan: 15, minValueSpan: 8, start: 90 }] : undefined,
    xAxis: {
      type: 'category',
      boundaryGap: false,
      offset: 10,
      data: labels,
      axisLabel: {
        formatter: function (value) {
          return `${DateUtils.format(value, format)}`;
        },
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: '{value} ',
      },
    },
    series: indicatorOptions.map(({ label, value }, index) => ({
      name: label,
      smooth: true,
      areaStyle: {
        opacity: 0,
      },
      type: 'line',
      data: data.map((v) => v[value]),
      show: index < defaultShowCount,
    })),
  };
};

export const LiveLineChart = ({
  accountId,
  startDate,
  endDate,
}: {
  accountId: Key;
  startDate?: string;
  endDate?: string;
}) => {
  const { user } = useAppContext();

  const indicatorOptions = [
    {
      label: dict('LIVE_TOTAL'),
      value: 'liveTotal',
    },
    {
      label: dict('LIVE_INDICATOR_WATCH_UV'),
      value: 'lpScreenLiveWatchUv',
    },
    {
      label: dict('LP_SCREEN_LIVE_LIKE_COUNT'),
      value: 'lpScreenLiveLikeCount',
    },
    {
      label: dict('LIVE_INDICATOR_COMMENT_COUNT'),
      value: 'lpScreenLiveCommentCount',
    },
    {
      label: dict('LP_SCREEN_LIVE_SHARE_COUNT'),
      value: 'lpScreenLiveShareCount',
    },
  ];

  const { data = {}, loading } = useRequest(
    async () => {
      const list = await getLiveStreamTrend({
        accountId,
        bid: user?.bid,
        startDate,
        endDate,
        mediaId: MEDIA_KEY_ENUM.WEIXIN,
      });

      return list.reduce((obj, cur) => {
        obj[DateUtils.format(cur['liveDate'], DATE_FORMAT_STR)] = cur;
        return obj;
      }, {});
    },
    { refreshDeps: [accountId, startDate, endDate, user?.bid] },
  );

  return (
    <>
      <Spin spinning={loading}>
        <ChartCore
          option={getOption(Object.values(data), Object.keys(data), indicatorOptions, DATE_FORMAT_STR)}
          notMerge
          style={{ height: 400 }}
        />
      </Spin>
    </>
  );
};

const LiveDetailLineChart = ({
  roomId,
  chartRawData,
  options,
}: {
  roomId: string;
  chartRawData?: WxLiveStreamDetailTrendVo[];
  options: Option[];
}) => {
  const data = useCreation(
    () =>
      (chartRawData || []).reduce((obj, cur) => {
        obj[DateUtils.format(cur['dimensions'], DATE_MINI_FORMAT_STR)] = cur;
        return obj;
      }, {}),

    [roomId, chartRawData],
  );

  return (
    <>
      <Spin spinning={chartRawData === undefined}>
        <ChartCore
          option={getOption(Object.values(data), Object.keys(data), options)}
          empty={isEmpty(data)}
          style={{ height: 400 }}
          notMerge
        />
      </Spin>
    </>
  );
};

export default LiveDetailLineChart;
