import { EventEmitter } from 'ahooks/lib/useEventEmitter';
import React, { useEffect, useRef } from 'react';
import Player, { Events } from 'xgplayer';
// import HlsPlayer from 'xgplayer-hls.js';
import 'xgplayer/dist/index.min.css';
import { VideoEventType } from '.';
import type { WxLiveStreamData } from '@/services/live_stream/wx/type';
import { dict } from '@/hooks/useChangeLocale';

type VideoPlayProps = {
  roomId: string;
  detail?: WxLiveStreamData;
  event$: EventEmitter<VideoEventType>;
};

export const VideoPlay: React.FC<VideoPlayProps> = ({ detail, event$ }) => {
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<Player>();

  const videoPlayURL = detail?.replayOssUrl || detail?.replayUrl;

  //字幕视频逻辑
  useEffect(() => {
    if (!videoPlayURL) return;
    const player = new Player({
      el: videoContainerRef.current as HTMLDivElement,
      autoplay: false,
      playbackRate: [0.5, 0.75, 1, 1.5, 2, 5, 10],
      // url: detail?.replayUrl?.split('?')[0],
      url: videoPlayURL,
      width: '100%',
      height: 300,
      isLive: false,
      // fluid: true, // 流体
      cssFullscreen: false,
      poster: detail?.coverUrl,
      fitVideoSize: 'fixWidth',
      // plugins: [HlsPlayer],
    });

    playerRef.current = player;

    //定时或快时时抓取 更新时间
    const onSeeked = (event) => {
      const { currentTime } = event;
      event$.emit({ action: 'updatePlayTime', value: Math.floor(currentTime) });
    };

    player.on(Events.TIME_UPDATE, onSeeked);
    player.on(Events.SEEKED, onSeeked);

    return () => {
      player.offAll();
    };
  }, [videoPlayURL]);

  event$.useSubscription(({ action, value }) => {
    if (action == 'updateVideoPlayTime') {
      if (playerRef.current) {
        playerRef.current.currentTime = value;
        playerRef.current.play();
        event$.emit({ action: 'updatePlayTime', value });
      }
    }
  });

  return (
    <>
      <span>{dict('ACTION_LIVE_REPLAY')}</span>
      <div style={{ padding: 5 }}>
        <div ref={videoContainerRef} />
      </div>
    </>
  );
};
