import { headerFormCardProps } from '@/constants/const';
import { dict } from '@/hooks/useChangeLocale';
import {
  CHANNEL_COUNT_TYPE,
  ChannelIndicatorChartOptions,
  TIME_TYPE_ENUM,
  WatchProfileOptions,
  WholeIndicatorChartOptions,
} from '@/pages/live/report/weixin/detail/const.var';
import {
  getWxLiveStreamDataDetail,
  getWxLiveStreamDetailTrend,
  getWxLiveStreamTotalTrend,
  getWxLiveWatchProfile,
} from '@/services/live_stream/wx';
import { ProCard } from '@ant-design/pro-components';
import { Radio, Spin } from '@antd';
import { useRequest } from 'ahooks';
import DataStatisticCard from './DataStatisticCard';
import ChannelChart from './data-chart/ChannelChart';
import LineTrendChart from './data-chart/LineChart';
import CommonPieChart, { getAgeLabel } from './watch-profile-chart/CommonPieChart';
import RankBar from './watch-profile-chart/RankBar';
import { useState } from 'react';
import { WatchProfileTypeEnum } from '@/services/live_stream/wx/type';
import { Col, Row } from '@antd';
import { ProvienceTable } from './tables/RegionTable';

type DataProps = {
  roomId: string;
};

// 获取指定类型的画像数据
const getProfileData = (data: AnyObject[] = [], type) => {
  return data
    .filter((item) => item.type == type)
    .map((item) => ({
      name: item.typeVal,
      value: +item.dataVal,
    }));
};

const Data: React.FC<DataProps> = ({ roomId }) => {
  const [watchProfileType, setWatchProfileType] = useState(WatchProfileTypeEnum.ALL);

  /**
   * 获取直播数据
   */
  const { data, loading } = useRequest(
    () => {
      return Promise.all([
        getWxLiveStreamDataDetail(roomId),
        getWxLiveStreamTotalTrend({
          roomId,
          timeRange: TIME_TYPE_ENUM.WHOLE_LIVE,
        }),
        // getWxLiveWatchProfile(roomId),
        getWxLiveStreamDetailTrend({
          roomId,
          timeType: TIME_TYPE_ENUM.WHOLE_LIVE,
        }),
      ]);
    },
    { refreshDeps: [roomId] },
  );

  /**
   * 加载画像数据
   */
  const { data: profileData, loading: profileLoading } = useRequest(
    () => getWxLiveWatchProfile(roomId, watchProfileType),
    {
      refreshDeps: [roomId, watchProfileType],
    },
  );

  const [streamData, totalTrendData, chartTrendData = []] = data || [];
  // 2 年龄 3 性别 4 地域 5 粉丝  9人群分布 10省份  11 城市
  const formatProfileData = JSON.parse(profileData || '[]');

  const isFansWatchProfiltType = watchProfileType == WatchProfileTypeEnum.FANS;

  return (
    <Spin spinning={loading}>
      <DataStatisticCard liveData={streamData} totalTrendData={totalTrendData} />

      <ProCard.Group gutter={[16, 16]} wrap loading={loading}>
        <ProCard title={dict('TREND_ANALYSIS')} {...headerFormCardProps} colSpan={12}>
          <LineTrendChart roomId={roomId} chartRawData={chartTrendData} options={WholeIndicatorChartOptions} />
        </ProCard>
        <ProCard title={dict('LIVIE_CHANNEL_TRAFFIC_ANALYSIS')} {...headerFormCardProps} colSpan={12}>
          <LineTrendChart roomId={roomId} chartRawData={chartTrendData} options={ChannelIndicatorChartOptions} />
        </ProCard>

        <ProCard title={dict('CHANNEL_DISTRIBUTION')} {...headerFormCardProps} colSpan={24}>
          <ChannelChart liveStreamData={streamData} type={CHANNEL_COUNT_TYPE.CUMULATIVE_WATCH_COUNT} roomId={roomId} />
        </ProCard>
      </ProCard.Group>

      <ProCard.Group
        gutter={[16, 16]}
        wrap
        style={{ marginTop: 16 }}
        title={
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            style={{
              marginLeft: -15,
            }}
            value={watchProfileType}
            options={WatchProfileOptions}
            onChange={(e) => setWatchProfileType(e.target.value)}
          />
        }
      >
        <ProCard title={dict('GENDER_DISTRIBUTION')} {...headerFormCardProps} colSpan={12}>
          <CommonPieChart loading={profileLoading} data={getProfileData(formatProfileData, 3)} />
        </ProCard>

        <ProCard title={dict('AGE_DISTRIBUTION')} {...headerFormCardProps} colSpan={12}>
          <CommonPieChart
            loading={profileLoading}
            data={getProfileData(formatProfileData, 2)}
            formatName={getAgeLabel}
          />
        </ProCard>

        {!isFansWatchProfiltType && (
          <ProCard title={dict('FAN_DISTRIBUTION')} {...headerFormCardProps} colSpan={12}>
            <CommonPieChart loading={profileLoading} data={getProfileData(formatProfileData, 5)} />
          </ProCard>
        )}

        <ProCard title={dict('CROWD_DISTRIBUTION')} {...headerFormCardProps} colSpan={12}>
          <RankBar loading={profileLoading} data={getProfileData(formatProfileData, 9)} />
        </ProCard>
      </ProCard.Group>

      <Row gutter={[16, 0]} style={{ margin: 0 }}>
        <Col span={24}>
          {/* 省份数据 */}
          <ProvienceTable provinceData={getProfileData(formatProfileData, 10)} />
        </Col>
      </Row>
    </Spin>
  );
};

export default Data;
