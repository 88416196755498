import { AccountAvator } from '@/components/AccountAvator';
import { FormCard } from '@/components/PageCard';
import { MEDIA_KEY_ENUM, ROUTE_PATH } from '@/constants/const';
import { useAppContext } from '@/contexts/AppContext';
import useTableRequest from '@/hooks/useTableRequest';
import { getSoretParams } from '@/pages/live/const.var';
import { DateUtils } from '@/utils/dateUtil';
import { App, Space, Spin, Table, Typography } from '@antd';
import { useBoolean, useCreation, useRequest, useSafeState } from 'ahooks';
import { camelCase, isEmpty, isNull } from 'lodash-es';
import { downloadDyLiveStream, getDyLiveStreamPages } from '@/services/live_stream/dy';
import { usePermission } from '@/hooks/usePermission';
import { AUTH } from '@/constants/permission-code';
import { dict } from '@/hooks/useChangeLocale';
import type { LiveStreamPageParams } from '@/services/live_stream/all/type';
import CustomColumnModal from '@/components/CustomColumnModal';
import { DyColumnGroup } from '@/components/CustomColumnModal/type';
import { getLiveReportDynamicColumn } from '@/constants/dynamic-column/live/report/douyin';
import { convertToTableColumns } from '@/constants/dynamic-column';
import { ColumnType } from 'antd/es/table';
import { Format } from '@/utils/format';
import { useAddTagModal } from '@/components/modal/AddTagModal';
import { updateLiveTag } from '@/services/live_stream/all';
import { useCollectMaterialModal } from '@/components/modal/useCollectCaseModal';
import { BarChartOutlined, CommentOutlined, HeartOutlined, PlaySquareOutlined, TagsOutlined } from '@ant-design/icons';
import { useTagColumns } from '@/hooks/tag/useTagColumn';
import { useEffect } from 'react';

const { Link } = Typography;

type ListPros = {
  params: RequestSearchParams;
};
const List = ({ params }: ListPros) => {
  const { message } = App.useApp();
  const { getEnum, user } = useAppContext();
  const [downloadParams, setDownloadParams] = useSafeState<RequestSearchParams>({});
  const liveStreamSortEnums = getEnum('LiveStreamSortParamEnum');
  const [customColumnVisible, { setTrue: showCustomColumn, setFalse: hideCustomColumn }] = useBoolean(false);

  const liveStreamSortFieldMap = useCreation(
    () =>
      liveStreamSortEnums.reduce((map, cur) => {
        const { name } = cur;
        const [, fieldName] = name.split('.');
        map[camelCase(fieldName)] = name;
        return map;
      }, {}),
    [liveStreamSortEnums],
  );

  const { refresh: download, loading: downloadLoading } = useRequest(
    () => downloadDyLiveStream(downloadParams as LiveStreamPageParams),
    {
      manual: true,
    },
  );

  const { tableProps, refresh } = useTableRequest(
    ({ pageIndex, pageSize, sorter = { field: 'liveStartTime', order: 'descend' } }) => {
      const downloadParams = {
        ...params,
        tagValueIds: params.tagValueIds?.map((v) => v[1]),
        ...getSoretParams(sorter, liveStreamSortFieldMap),
      };
      setDownloadParams(downloadParams);
      return getDyLiveStreamPages({
        ...downloadParams,
        pageIndex,
        pageSize,
      });
    },
    { refreshDeps: [params], debounceWait: 500 },
  );

  const { run: updateTag, loading: updateTagLoading } = useRequest(
    async (itemId, tagIds) => {
      try {
        await updateLiveTag(itemId, tagIds, user?.bid);
        message.success(dict('OPERATION_SUCCESS'));
        refresh();
      } catch (error) {
        message.error(dict('OPERATION_FAILURE'));
      }
    },
    {
      refreshDeps: [user],
      manual: true,
    },
  );

  const [AddTagModal, showTagModal] = useAddTagModal();
  const [CollectMaterialModal, collectLoading, showCollectMaterialModal] = useCollectMaterialModal();

  const { getBtnPermissionConfig } = usePermission();
  const viewAuthConfig = getBtnPermissionConfig(AUTH.LIVE_STREAM.VIEW);
  const addfavourAuthConfig = getBtnPermissionConfig(AUTH.MATERIAL_MANAGEMENT.CREATE);

  const hasTags = !getBtnPermissionConfig(AUTH.TAG_MANAGEMENT.PAGE)?.disabled;
  const [tagColumns] = useTagColumns();
  const [tableColumnsConfig, setTableColumnsConfig] = useSafeState(getLiveReportDynamicColumn(true));

  useEffect(() => {
    if (!isEmpty(tagColumns)) setTableColumnsConfig(getLiveReportDynamicColumn(true, tagColumns as any[]));
  }, [tagColumns]);

  const columns = convertToTableColumns(tableColumnsConfig, [], { width: 150 }).map((column): ColumnType<AnyObject> => {
    const key = column.key as string;
    if (key == 'roomId') {
      return {
        ...column,
        width: 160,
      };
    } else if (key == 'name') {
      return {
        ...column,
        width: 200,
        render: (_, record) => <AccountAvator avaterUrl={record?.avatarUrl} name={record?.name} />,
      };
    }
    // else if (key == 'tagInfoList') {
    //   return {
    //     ...column,
    //     width: 200,
    //     ellipsis: true,
    //     render: (tagInfoList) =>
    //       isEmpty(tagInfoList) ? '-' : tagInfoList?.map((v) => `${v.tagName}-${v.tagValueName}`).join(','),
    //   };
    // }
    else if (key.startsWith('lpScreen') || key.startsWith('live')) {
      let columnConfig = column;
      if (key.endsWith('Rate') || key.endsWith('Ratio')) {
        columnConfig = { ...columnConfig, render: (v) => (isNull(v) ? '-' : `${v}%`) };
      } else if (key.endsWith('Cost')) {
        columnConfig = { ...columnConfig, render: (v) => Format.toAmountNumber(v) };
      } else if (key.endsWith('Duration')) {
        columnConfig = { ...columnConfig, render: (v) => DateUtils.formatDiffrenceTime(v) };
      } else if (key.endsWith('Time')) {
        columnConfig = {
          ...columnConfig,
          defaultSortOrder: 'liveStartTime' == key ? 'descend' : undefined,
          render: (v) => DateUtils.formatDateTime(v),
        };
      }
      return {
        ...columnConfig,
        sorter: true,
      };
    } else if (key == 'operation') {
      return {
        ...column,
        align: 'center',
        fixed: 'right',
        width: 140,
        render: (_, record) => {
          const { roomId } = record;
          const url = `${ROUTE_REPORT_PATH}/${ROUTE_PATH.DOUYIN}/${ROUTE_PATH.DETAIL}/${roomId}`;

          return (
            <Space>
              <Link onClick={() => window.open(`${BASE_PATH}${url}?type=data`)} {...viewAuthConfig}>
                <BarChartOutlined className="action-icon" title={dict('ACTION_REPORT')} />
              </Link>
              <Link onClick={() => window.open(`${BASE_PATH}${url}?type=barrage`)} {...viewAuthConfig}>
                <CommentOutlined className="action-icon" title={dict('BARRAGE')} />
              </Link>
              <Link onClick={() => window.open(`${BASE_PATH}${url}?type=playhistory`)} {...viewAuthConfig}>
                <PlaySquareOutlined className="action-icon" title={dict('ACTION_REPLAY')} />
              </Link>
              {hasTags && (
                <Link
                  title={dict('ACTION_ADD_NAME')}
                  onClick={() => {
                    showTagModal(
                      record?.tagInfoList?.filter((v) => v.type == 'manual').map((v) => [v.tagId, v.tagValId]),
                      record?.tagInfoList?.filter((v) => v.type == 'automatic').map((v) => [v.tagId, v.tagValId]),
                      record.roomId,
                    );
                  }}
                >
                  <TagsOutlined className="action-icon" title={dict('ACTION_ADD_NAME', { name: dict('TAG') })} />
                </Link>
              )}
              <Link
                title={record.topCase ? dict('JOIN_EXCELLENT_CASE_ALREADY') : dict('JOIN_EXCELLENT_CASE')}
                disabled={record.topCase}
                {...addfavourAuthConfig}
                onClick={() => showCollectMaterialModal(record.roomId, 'live', MEDIA_KEY_ENUM.DOUYIN)}
              >
                <HeartOutlined className="action-icon" />
              </Link>
            </Space>
          );
        },
      };
    }

    return {
      ...column,
    };
  });

  const ROUTE_REPORT_PATH = `/${ROUTE_PATH.LIVE}/${ROUTE_PATH.REPORT}`;

  return (
    <FormCard
      title={dict('LIVE_DATA')}
      style={{ marginBottom: 10 }}
      extra={
        <Space>
          <Link onClick={showCustomColumn}>{dict('CUSTOM_COLUMNS')}</Link>

          <Spin spinning={downloadLoading}>
            <Link onClick={download} {...getBtnPermissionConfig(AUTH.LIVE_STREAM.DOWNLOAD)}>
              {dict('ACTION_DOWNLOAD_DATA')}
            </Link>
          </Spin>
        </Space>
      }
    >
      <Table
        {...tableProps}
        loading={tableProps.loading || updateTagLoading || collectLoading}
        scroll={{ x: 150 * columns.length, y: 500 }}
        columns={columns}
      />
      <CustomColumnModal
        visible={customColumnVisible}
        tableColumnsConfig={tableColumnsConfig}
        defaultTableColumnsConfig={tableColumnsConfig}
        onCancel={hideCustomColumn}
        onConfigOK={(config: DyColumnGroup[]) => {
          setTableColumnsConfig(config);
          hideCustomColumn();
        }}
      />
      <AddTagModal
        onTagSelect={(tagIds, targetId) => {
          updateTag(
            targetId,
            tagIds.map((v) => v[1]),
          );
        }}
      />
      <CollectMaterialModal onCollectedOk={refresh} />
    </FormCard>
  );
};

export default List;
