import { StatisticCardPanel, StatisticItem } from '@/components/StatisticCard';
import type { LiveDataVO } from '@/services/live_stream/all/type';
import { Format } from '@/utils/format';
import { ProCard } from '@ant-design/pro-components';
import { LiveIndicatorTitle } from '@/pages/live/report/components/LiveIndicatorTitle';
import { isNil } from 'lodash';
import { headerFormCardProps } from '@/constants/const';
import { dict } from '@/hooks/useChangeLocale';

type DataStatisticCardProps = {
  liveDataVO?: LiveDataVO;
};

const DataStatisticCard = ({ liveDataVO }: DataStatisticCardProps) => {
  const loading = !liveDataVO;
  const audienceItems: StatisticItem[] = [
    {
      title: <LiveIndicatorTitle type="lpScreenLiveShowCount" />, //曝光次数
      value: Format.toAmountNumber(liveDataVO?.lpScreenLiveShowCount),
      suffix: dict('STATIST_UNIT_CI'),
      other: [
        {
          title: <LiveIndicatorTitle type="lpScreenLiveEnterRatio" />, //曝光进入率
          value: Format.toAmountNumber(liveDataVO?.lpScreenLiveEnterRatio, 2),
          suffix: '%',
        },
      ],
    },
    {
      title: <LiveIndicatorTitle type="lpScreenLiveWatchCount" />, //观看次数
      value: Format.toAmountNumber(liveDataVO?.lpScreenLiveWatchCount),
      suffix: dict('STATIST_UNIT_CI'),
    },
    {
      title: <LiveIndicatorTitle type="lpScreenLiveWatchUv" />, // 累计观看人数,
      value: Format.toAmountNumber(liveDataVO?.lpScreenLiveWatchUv),
      suffix: dict('STATIST_UNIT_REN'),
      other: [
        {
          title: <LiveIndicatorTitle type="lpScreenLiveFansWatchRatio" />, //粉丝占比
          value: Format.toAmountNumber(liveDataVO?.lpScreenLiveFansWatchRatio, 2),
          suffix: '%',
        },
      ],
    },
    {
      title: <LiveIndicatorTitle type="lpScreenLiveWatchGtMinCount" />, //＞1分钟观看人次
      value: Format.toAmountNumber(liveDataVO?.lpScreenLiveWatchGtMinCount),
      suffix: dict('STATIST_UNIT_REN_CI'),
    },
    {
      title: <LiveIndicatorTitle type="lpScreenLiveAvgWatchDuration" />, //人均观看时长
      mValues: [
        {
          value: isNil(liveDataVO?.lpScreenLiveAvgWatchDuration)
            ? '-'
            : Math.round(liveDataVO?.lpScreenLiveAvgWatchDuration || 0),
          suffix: dict('STATIST_UNIT_SEC'),
        },
      ],
    },
    {
      title: <LiveIndicatorTitle type="lpScreenLiveMaxWatchUvByMinute" />, //最高在线人数
      value: Format.toAmountNumber(liveDataVO?.lpScreenLiveMaxWatchUvByMinute),
      suffix: dict('STATIST_UNIT_REN'),
      other: [
        {
          title: <LiveIndicatorTitle type="lpScreenLiveAvgOnlineUvByRoom" />, //平均在线人数
          value: Format.toAmountNumber(liveDataVO?.lpScreenLiveAvgOnlineUvByRoom),
          suffix: dict('STATIST_UNIT_REN'),
        },
      ],
    },
    {
      title: <LiveIndicatorTitle type="lpScreenLiveUserRealtime" />, //实时在线人数
      value: Format.toAmountNumber(liveDataVO?.lpScreenLiveUserRealtime),
      other: [
        {
          title: <LiveIndicatorTitle type="lpScreenUvWithPreview" />, //看过
          value: Format.toAmountNumber(liveDataVO?.lpScreenUvWithPreview),
          suffix: dict('STATIST_UNIT_REN'),
        },
      ],
    },
  ];

  const interactionItems = [
    {
      title: <LiveIndicatorTitle type="lpScreenLiveShareCount" />, //分享次数
      value: Format.toAmountNumber(liveDataVO?.lpScreenLiveShareCount),
      suffix: dict('STATIST_UNIT_CI'),
      other: [
        {
          title: <LiveIndicatorTitle type="lpScreenLiveShareUv" />, //分享人数
          value: Format.toAmountNumber(liveDataVO?.lpScreenLiveShareUv),
          suffix: dict('STATIST_UNIT_REN'),
        },
        {
          title: <LiveIndicatorTitle type="lpScreenLiveShareRatio" />, //分享率
          value: Format.toAmountNumber(liveDataVO?.lpScreenLiveShareRatio, 2),
          suffix: '%',
        },
      ],
    },
    {
      title: <LiveIndicatorTitle type="lpScreenLiveLikeCount" />, //点赞次数
      value: Format.toAmountNumber(liveDataVO?.lpScreenLiveLikeCount),
      suffix: dict('STATIST_UNIT_CI'),
      other: [
        {
          title: <LiveIndicatorTitle type="lpScreenLiveLikeUv" />, //点赞人数
          value: Format.toAmountNumber(liveDataVO?.lpScreenLiveLikeUv),
          suffix: dict('STATIST_UNIT_REN'),
        },
        {
          title: <LiveIndicatorTitle type="lpScreenLiveLikeRatio" />, //点赞率
          value: Format.toAmountNumber(liveDataVO?.lpScreenLiveLikeRatio, 2),
          suffix: '%',
        },
      ],
    },
    {
      title: <LiveIndicatorTitle type="lpScreenLiveCommentCount" />, //评论次数
      value: Format.toAmountNumber(liveDataVO?.lpScreenLiveCommentCount),
      suffix: dict('STATIST_UNIT_CI'),
      other: [
        {
          title: <LiveIndicatorTitle type="lpScreenLiveCommentUv" />, //评论人数
          value: Format.toAmountNumber(liveDataVO?.lpScreenLiveCommentUv),
          suffix: dict('STATIST_UNIT_REN'),
        },
        {
          title: <LiveIndicatorTitle type="lpScreenLiveCommentRatio" />, //评论率
          value: Format.toAmountNumber(liveDataVO?.lpScreenLiveCommentRatio, 2),
          suffix: '%',
        },
      ],
    },
    {
      title: <LiveIndicatorTitle type="lpScreenLiveInteractionCount" />, //互动次数
      value: Format.toAmountNumber(liveDataVO?.lpScreenLiveInteractionCount),
      suffix: dict('STATIST_UNIT_CI'),
      other: [
        {
          title: <LiveIndicatorTitle type="lpScreenLiveInteractionUvCount" />, //互动人数
          value: Format.toAmountNumber(liveDataVO?.lpScreenLiveInteractionUvCount),
          suffix: dict('STATIST_UNIT_REN'),
        },
        {
          title: <LiveIndicatorTitle type="lpScreenLiveInteractionRatio" />, //互动率
          value: Format.toAmountNumber(liveDataVO?.lpScreenLiveInteractionRatio, 2),
          suffix: '%',
        },
      ],
    },
    {
      title: <LiveIndicatorTitle type="lpScreenLiveFansClubJoinUv" />, //加粉丝团人数
      value: Format.toAmountNumber(liveDataVO?.lpScreenLiveFansClubJoinUv),
      suffix: dict('STATIST_UNIT_REN'),
      other: [
        {
          title: <LiveIndicatorTitle type="lpScreenLiveFansClubJoinUvRatio" />, // 加团率
          value: Format.toAmountNumber(liveDataVO?.lpScreenLiveFansClubJoinUvRatio, 2),
          suffix: '%',
        },
      ],
    },
  ];

  const conversionItems = [
    {
      title: <LiveIndicatorTitle type="lpScreenLiveFollowUv" />, //涨粉量
      value: Format.toAmountNumber(liveDataVO?.lpScreenLiveFollowUv),
      suffix: dict('STATIST_UNIT_REN'),
      other: [
        {
          title: <LiveIndicatorTitle type="lpScreenLiveFollowRatio" />, //关注率
          value: Format.toAmountNumber(liveDataVO?.lpScreenLiveFollowRatio, 2),
          suffix: '%',
        },
      ],
    },

    {
      title: <LiveIndicatorTitle type="lpScreenClueUv" />, //全场景线索人数
      value: Format.toAmountNumber(liveDataVO?.lpScreenClueUv),
      suffix: dict('STATIST_UNIT_REN'),
      other: [
        {
          title: <LiveIndicatorTitle type="lpScreenLiveClueConvertRatio" />, //线索转化率
          value: Format.toAmountNumber(liveDataVO?.lpScreenLiveClueConvertRatio, 2),
          suffix: '%',
        },
      ],
    },
    {
      title: <LiveIndicatorTitle type="lpScreenMsgConversationCount" />, //私信人数
      value: Format.toAmountNumber(liveDataVO?.lpScreenMsgConversationCount),
      suffix: dict('STATIST_UNIT_REN'),
      other: [
        {
          title: <LiveIndicatorTitle type="lpScreenLongtermMsgClueUv" />, //私信长效转化
          value: Format.toAmountNumber(liveDataVO?.lpScreenLongtermMsgClueUv),
          suffix: '%',
        },
      ],
    },
    {
      title: <LiveIndicatorTitle type="lpScreenAdFormCount" />, //表单提交数
      value: Format.toAmountNumber(liveDataVO?.lpScreenAdFormCount),
      suffix: dict('STATIST_UNIT_CI'),
      other: [
        {
          title: <LiveIndicatorTitle type="lpScreenAdFormCost" />, //表单成本
          value: Format.toAmountNumber(liveDataVO?.lpScreenAdFormCost, 2),
          suffix: dict('STATIST_UNIT_YUAN'),
        },
      ],
    },

    {
      title: <LiveIndicatorTitle type="lpScreenAdBizWechatAddCount" />, //企业微信添加数
      value: Format.toAmountNumber(liveDataVO?.lpScreenAdBizWechatAddCount),
      suffix: dict('STATIST_UNIT_CI'),
      other: [
        {
          title: <LiveIndicatorTitle type="lpScreenAdBizWechatCost" />, //加微信成本
          value: Format.toAmountNumber(liveDataVO?.lpScreenAdBizWechatCost),
          suffix: dict('STATIST_UNIT_YUAN'),
        },
      ],
    },

    {
      title: <LiveIndicatorTitle type="lpScreenLiveIconClickCount" />, //小风车点击次数
      value: Format.toAmountNumber(liveDataVO?.lpScreenLiveIconClickCount),
      suffix: dict('STATIST_UNIT_CI'),
      other: [
        {
          title: <LiveIndicatorTitle type="lpScreenLiveIconClickRate" />, //小风车点击率
          value: Format.toAmountNumber(liveDataVO?.lpScreenLiveIconClickRate, 2),
          suffix: '%',
        },
      ],
    },

    {
      title: <LiveIndicatorTitle type="lpScreenLiveClueBusinessCardClickCount" />, //卡片点击次数
      value: Format.toAmountNumber(liveDataVO?.lpScreenLiveClueBusinessCardClickCount),
      suffix: dict('STATIST_UNIT_CI'),
      other: [
        {
          title: <LiveIndicatorTitle type="lpScreenLiveClueBusinessCardClickRate" />, //卡片点击率
          value: Format.toAmountNumber(liveDataVO?.lpScreenLiveClueBusinessCardClickRate, 2),
          suffix: '%',
        },
      ],
    },

    {
      title: <LiveIndicatorTitle type="lpScreenLiveStatCost" />, //广告消耗
      value: Format.toAmountNumber(liveDataVO?.lpScreenLiveStatCost),
      suffix: dict('STATIST_UNIT_YUAN'),
      other: [
        {
          title: <LiveIndicatorTitle type="lpScreenClueCost" />, //线索成本
          value: Format.toAmountNumber(liveDataVO?.lpScreenClueCost, 2),
          suffix: dict('STATIST_UNIT_YUAN'),
        },
      ],
    },
  ];

  return (
    <ProCard.Group direction="column" gutter={[16, 12]}>
      <ProCard {...headerFormCardProps} title={dict('LIVE_STATIST_AUDIENCE_DATA')}>
        <StatisticCardPanel loading={loading} items={audienceItems} columns={5} />
      </ProCard>

      <ProCard {...headerFormCardProps} title={dict('LIVE_STATIST_INTERACTIVE_DATA')}>
        <StatisticCardPanel loading={loading} items={interactionItems} columns={5} />
      </ProCard>

      <ProCard {...headerFormCardProps} title={dict('LIVE_STATIST_CONVERT_DATA')}>
        <StatisticCardPanel loading={loading} items={conversionItems} columns={5} />
      </ProCard>
    </ProCard.Group>
  );
};

export default DataStatisticCard;
