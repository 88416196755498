import ChartCore from '@/components/ChartCore';
import { getLiveStreamDetailTrend } from '@/services/live_stream/all';
import type { HighlightTime, LiveStreamData } from '@/services/live_stream/all/type';
import { DATE_MINI_FORMAT_STR, DateUtils } from '@/utils/dateUtil';
import { Radio, Select, Space, Spin, Typography } from '@antd';
import { useBoolean, useCreation, useMemoizedFn, useRequest, useSafeState } from 'ahooks';
import { EventEmitter } from 'ahooks/lib/useEventEmitter';
import dayjs from 'dayjs';
import { EChartsInstance } from 'echarts-for-react';
import { get, max, uniqBy } from 'lodash';
import { useEffect, useState } from 'react';
import type { VideoEventType } from '.';
import { dict } from '@/hooks/useChangeLocale';

const { Text } = Typography;

const padding = 80;
const HighLightMoment = dict('LIVE_DETAIL_TAB_HIGH_LIGHT');
const getOption = (data, labels, indicator, indicatorOptions, highlightList) => {
  let maxData = 0;

  const options = {
    tooltip: {
      trigger: 'axis',
      // axisPointer: {
      //   type: 'shadow',
      // },
      formatter: function (params) {
        if (!params.length) return;
        let str = `${params[0].axisValue}<br/>`;
        str += uniqBy(params, 'seriesName')
          .map((item: AnyObject, idx) => {
            const records = params.filter((record) => record.seriesName == item.seriesName);
            const value =
              item.seriesName == HighLightMoment
                ? records.map((record) => record.data?.record?.reason).join('、')
                : records.map((record) => record.data).join(' / ');
            return `${item.marker}${item.seriesName}:&nbsp; <span style="float:right;font-weight:bold;">${value}</span>`;
          })
          .join('<br/>');
        return str;
      },
    },
    grid: [
      {
        top: 20,
        left: padding,
        right: padding,
        bottom: 60,
        containLabel: false,
      },
      {
        left: padding,
        right: padding,
        bottom: 20,
        height: '1',
        containLabel: false,
      },
    ],
    dataZoom: {
      type: 'inside',
      xAxisIndex: [0, 1],
    },
    xAxis: [
      {
        type: 'category',
        // offset: 10,
        data: labels,
        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: function (value) {
            return `${DateUtils.format(value, 'HH:mm')}`;
          },
          margin: 10,
        },
      },
      {
        gridIndex: 1,
        type: 'category',
        data: labels,
        axisLine: {
          lineStyle: {
            color: '#F4F6FB',
            width: 15,
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
      },
    ],

    series: [
      ...indicatorOptions
        .filter((v) => indicator.includes(v.value))
        .map(({ label, value }) => {
          const indicatorData = data.map((v) => v[value]);
          maxData = max([maxData, ...indicatorData]);

          return {
            name: label,
            smooth: true,
            xAxisIndex: 0,
            yAxisIndex: 0,
            areaStyle: {
              opacity: 0,
            },
            type: 'line',
            data: indicatorData,
          };
        }),
      {
        name: HighLightMoment,
        type: 'scatter',
        xAxisIndex: 1,
        yAxisIndex: 1,
        data: [
          ...(highlightList || []).map((v) => ({
            value: [DateUtils.format(v.startTime, DATE_MINI_FORMAT_STR), 0],
            record: v,
          })),
        ],
        itemStyle: {
          color: '#C0D2F8',
          borderColor: '#709EF8',
        },
        symbol: 'circle',
        symbolSize: 8,
      },
    ],

    axisPointer: {
      snap: false,
      link: {
        xAxisIndex: 'all',
      },
    },
  };

  return {
    ...options,
    yAxis: [
      {
        type: maxData >= 10000 ? 'log' : 'value',
      },
      {
        name: HighLightMoment,
        type: 'value',
        gridIndex: 1,
        show: false,
      },
    ],
  };
};

enum TimeTypeEnum {
  min1 = 0,
  min5 = 1,
  hour = 2,
}

const tip = dict('LIVE_INDICATOR_TIP');
const defaultIndecator = ['lpScreenLiveMaxWatchUvByMinute', 'lpScreenLiveFansWatchUvByMinute', 'lpScreenLiveLikeCount'];

//人气指标（实时在线人数、观看次数、粉丝数）、
// 互动指标（点赞次数、分享次数、评论次数）、
// 转化指标（表单提交数、小风车点击次数、卡片点击次数）
// 以及其他指标（曝光次数、＞1分钟观看人数、加入粉丝团人数、线索量和私信人数）
const indicatorOptions = [
  {
    label: dict('LIVE_INDICATOR_GROUP_POPULARITY'),
    options: [
      { label: dict('LIVE_INDICATOR_USER_REALTIME'), value: 'lpScreenLiveMaxWatchUvByMinute' },
      // { label: '观看次数', value: 'lpScreenLiveWatchCount' },
      { label: dict('LIVE_INDICATOR_FANS_WATCH_UV'), value: 'lpScreenLiveFansWatchUvByMinute' },
    ],
  },
  {
    label: dict('LIVE_INDICATOR_GROUP_INTERACTIVE'),
    options: [
      { label: dict('LP_SCREEN_LIVE_LIKE_COUNT'), value: 'lpScreenLiveLikeCount' },
      // { label: dict('LP_SCREEN_LIVE_SHARE_COUNT'), value: 'lpScreenLiveShareCount' },
      { label: dict('LIVE_INDICATOR_COMMENT_COUNT'), value: 'lpScreenLiveCommentCount' },
    ],
  },
  {
    label: dict('LIVE_INDICATOR_GROUP_CONVERT'),
    options: [
      { label: dict('LIVE_INDICATOR_ICON_CLICK_COUNT'), value: 'lpScreenLiveIconClickCountByTime' },
      {
        label: dict('LIVE_INDICATOR_BUSINESS_CARD_CLICK_COUNT'),
        value: 'lpScreenLiveClueBusinessCardClickCountByTime',
      },
    ],
  },
  {
    label: dict('LIVE_INDICATOR_GROUP_OTHER'),
    options: [{ label: dict('LIVE_INDICATOR_SHOW_COUNT'), value: 'lpScreenLiveShowCount' }],
  },
];

type LineChartProps = {
  roomId: string;
  event$: EventEmitter<VideoEventType>;
  detail?: LiveStreamData;
  highlightList?: HighlightTime[];
};

const LineChart: React.FC<LineChartProps> = ({ roomId, event$, detail, highlightList }) => {
  const [chartInstance, setChartInstance] = useSafeState<EChartsInstance>();
  const [playTime, setPlayTime] = useSafeState<number>();
  const [timeType, setTimeType] = useSafeState(TimeTypeEnum.min1);
  const [indicator, setIndicator] = useState(defaultIndecator);
  const [hovering, { setTrue: setHovering, setFalse: cancelHovering }] = useBoolean(false);

  const { data = {}, loading } = useRequest(
    async () => {
      const list = await getLiveStreamDetailTrend({
        roomId,
        timeType,
      });

      return list.reduce((obj, cur) => {
        obj[DateUtils.format(cur['dimensions'], DATE_MINI_FORMAT_STR)] = cur;
        return obj;
      }, {});
    },
    { refreshDeps: [roomId, timeType] },
  );

  event$.useSubscription(({ action, value }) => {
    if (action == 'updatePlayTime') setPlayTime(value);
  });

  //视频播放进度
  const options = useCreation(() => {
    const dataMap = Object.entries(data);
    const xAxisLabels = Object.keys(data);

    const option = getOption(
      dataMap.map((v) => v[1]),
      xAxisLabels,
      indicator,
      indicatorOptions.reduce((arr, cur) => arr.concat(cur.options), [] as Option[]),
      highlightList,
    );

    return option;
  }, [data, indicator, highlightList, chartInstance]);

  useEffect(() => {
    if (hovering) return;
    chartInstance?.dispatchAction({
      type: 'showTip',
      seriesIndex: 0,
      dataIndex: Math.floor((playTime ?? 0) / 60),
    });
  }, [playTime, chartInstance, hovering]);

  const onChartClick = useMemoizedFn((params) => {
    if (params.seriesName == HighLightMoment) {
      const playTime = dayjs(params.value[0]).diff(dayjs(detail?.liveStartTime || '-'), 'second');
      event$.emit({ action: 'updateVideoPlayTime', value: Math.floor(playTime) });
    }
  });

  return (
    <>
      <span>{dict('REAL_TIME_DYNAMICS')}</span>
      <div>
        <Space style={{ display: 'flex', justifyContent: 'space-between', margin: 40 }}>
          <Select
            value={indicator}
            title={tip}
            mode="multiple"
            maxTagCount="responsive"
            options={indicatorOptions}
            onChange={(value) => {
              const valCount = get(value, 'length', 0);
              setIndicator(valCount < 1 ? defaultIndecator : (value as string[]).splice(-3));
            }}
            size="small"
            showSearch={false}
            style={{ width: 320 }}
          />

          <Radio.Group size="small" onChange={(e) => setTimeType(e.target.value)} value={timeType}>
            <Radio.Button value={TimeTypeEnum.min1}>{dict('TIME_MINUTE_TPL', { time: 1 })}</Radio.Button>
            <Radio.Button value={TimeTypeEnum.min5}>{dict('TIME_MINUTE_TPL', { time: 5 })}</Radio.Button>
            <Radio.Button value={TimeTypeEnum.hour}>{dict('TIME_HOUR_TPL', { time: 1 })}</Radio.Button>
          </Radio.Group>
        </Space>
        <Spin spinning={loading}>
          <div onMouseOver={setHovering} onMouseOut={cancelHovering}>
            <ChartCore
              option={options}
              notMerge
              style={{ height: 600 }}
              onChartReady={(instance) => {
                setTimeout(() => {
                  instance.resize();
                });
                setChartInstance(instance);
                instance.on('click', onChartClick);
              }}
            />
            <div style={{ position: 'absolute', bottom: 10, left: 15 }}>
              <Text>{HighLightMoment}</Text>
            </div>
          </div>
        </Spin>
      </div>
    </>
  );
};

export default LineChart;
