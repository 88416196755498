import type { LiveDataVO } from '@/services/live_stream/all/type';
import React from 'react';

import { StatisticCardPanel, StatisticItem } from '@/components/StatisticCard';
import { Format } from '@/utils/format';
import { ProCard } from '@ant-design/pro-components';
import { dict } from '@/hooks/useChangeLocale';
type DataCardProps = {
  liveDataVO?: LiveDataVO;
};
export const DataCard: React.FC<DataCardProps> = ({ liveDataVO }) => {
  const loading = !liveDataVO;
  const item1: StatisticItem[] = [
    {
      title: dict('LIVE_STATIST_VIEW_NUM'),
      value: Format.toAmountNumber(liveDataVO?.lpScreenLiveWatchUv),
      suffix: dict('STATIST_UNIT_REN'),
      otherLayoutDirection: 'horizontal',
      other: [
        {
          title: dict('LIVE_INDICATOR_MAX_WATCH_UV'),
          value: Format.toAmountNumber(liveDataVO?.lpScreenLiveMaxWatchUvByMinute),
          suffix: dict('STATIST_UNIT_REN'),
        },
        {
          title: dict('AVG_WATCH_TIME'),
          value: Format.toAmountNumber(liveDataVO?.lpScreenLiveAvgWatchDuration),
          suffix: dict('STATIST_UNIT_SEC'),
        },
        {
          title: dict('LIVE_INDICATOR_AVG_ONLINE_UV_BY_ROOM'),
          value: Format.toAmountNumber(liveDataVO?.lpScreenLiveAvgOnlineUvByRoom),
          suffix: dict('STATIST_UNIT_REN'),
        },
      ],
    },
  ];

  const item2: StatisticItem[] = [
    {
      title: dict('LIVE_INDICATOR_INTERACTION_COUNT'),
      value: Format.toAmountNumber(
        (liveDataVO?.lpScreenLiveLikeCount || 0) +
          (liveDataVO?.lpScreenLiveCommentCount || 0) +
          (liveDataVO?.lpScreenLiveShareCount || 0),
      ),
      suffix: dict('STATIST_UNIT_CI'),
      otherLayoutDirection: 'horizontal',
      other: [
        {
          title: dict('LP_SCREEN_LIVE_LIKE_COUNT'),
          value: Format.toAmountNumber(liveDataVO?.lpScreenLiveLikeCount),
          suffix: dict('STATIST_UNIT_CI'),
        },
        {
          title: dict('LIVE_INDICATOR_COMMENT_COUNT'),
          value: Format.toAmountNumber(liveDataVO?.lpScreenLiveCommentCount),
          suffix: dict('STATIST_UNIT_CI'),
        },
        {
          title: dict('LP_SCREEN_LIVE_SHARE_COUNT'),
          value: Format.toAmountNumber(liveDataVO?.lpScreenLiveShareCount),
          suffix: dict('STATIST_UNIT_CI'),
        },
      ],
    },
  ];

  //全场线索人数（数据来源留资服务中的组件留资数，若单场直播挂载多个留资组件
  const item3: StatisticItem[] = [
    {
      title: dict('LP_SCREEN_CLUE_UV'),
      value: Format.toAmountNumber(liveDataVO?.lpScreenClueUv),
      suffix: dict('STATIST_UNIT_REN'),
      // otherLayoutDirection: 'horizontal',
      // other: [
      //   {
      //     title: <LiveIndicatorTitle type="lpScreenLiveClueConvertRatio" />, //线索转化率
      //     value: Format.toAmountNumber(liveDataVO?.lpScreenLiveClueConvertRatio, 2),
      //     suffix: '%',
      //   },
      //   {
      //     title: <LiveIndicatorTitle type="lpScreenClueCost" />, //线索成本
      //     value: Format.toAmountNumber(liveDataVO?.lpScreenClueCost),
      //     suffix: dict('STATIST_UNIT_YUAN'),
      //   },
      // ],
    },
  ];
  return (
    <>
      <span>{dict('DATA_VIEW')}</span>
      <div>
        <ProCard.Group direction="row" loading={loading}>
          <ProCard>
            <StatisticCardPanel loading={loading} items={item1} />
          </ProCard>
          <ProCard.Divider type="vertical" />
          <ProCard>
            <StatisticCardPanel loading={loading} items={item2} />
          </ProCard>
          <ProCard.Divider type="vertical" />
          <ProCard>
            <StatisticCardPanel loading={loading} items={item3} />
          </ProCard>
        </ProCard.Group>
      </div>
    </>
  );
};
