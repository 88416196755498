import type { LiveStreamData } from '@/services/live_stream/all/type';
import { EventEmitter } from 'ahooks/lib/useEventEmitter';
import React, { useEffect, useRef } from 'react';
import Player, { Events } from 'xgplayer';
// import HlsPlayer from 'xgplayer-hls.js';
import { getOssStsM3u8 } from '@/services/oss';
import { Spin } from '@antd';
import { useRequest } from 'ahooks';
import HlsPlayer from 'xgplayer-hls';
import 'xgplayer/dist/index.min.css';
import type { VideoEventType } from '.';
import { dict } from '@/hooks/useChangeLocale';

type VideoPlayProps = {
  roomId: string;
  detail?: LiveStreamData;
  event$: EventEmitter<VideoEventType>;
};

export const VideoPlay: React.FC<VideoPlayProps> = ({ detail, event$ }) => {
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<Player>();

  const { data: mu38Url, loading } = useRequest(
    async () => {
      if (detail?.replayOssUrl) {
        const mu38Text = await getOssStsM3u8(detail?.replayOssUrl);
        return URL.createObjectURL(new Blob([mu38Text], { type: 'text/plain' }));
      }
      return detail?.replayUrl;
    },
    { refreshDeps: [detail?.replayOssUrl] },
  );

  //字幕视频逻辑
  useEffect(() => {
    if (!mu38Url) return;
    const player = new Player({
      el: videoContainerRef.current as HTMLDivElement,
      autoplay: true,
      playbackRate: [0.5, 0.75, 1, 1.5, 2, 5, 10],
      url: mu38Url,
      // url: URL.createObjectURL(new Blob([mu38Text], { type: 'text/plain' })),
      width: '100%',
      height: 300,
      isLive: false,
      // fluid: true, // 流体
      cssFullscreen: false,
      poster: detail?.coverUrl,
      fitVideoSize: 'fixWidth',
      plugins: [HlsPlayer],
    });

    playerRef.current = player;

    //定时或快时时抓取 更新时间
    const onSeeked = (event) => {
      const { currentTime } = event;
      event$.emit({ action: 'updatePlayTime', value: Math.floor(currentTime) });
    };

    player.on(Events.TIME_UPDATE, onSeeked);
    player.on(Events.SEEKED, onSeeked);

    return () => {
      player.offAll();
    };
  }, [mu38Url]);

  event$.useSubscription(({ action, value }) => {
    if (action == 'updateVideoPlayTime') {
      if (playerRef.current) {
        playerRef.current.currentTime = value;
        playerRef.current.play();
        event$.emit({ action: 'updatePlayTime', value });
      }
    }
  });

  return (
    <>
      <span>{dict('ACTION_LIVE_REPLAY')}</span>
      <div style={{ padding: 5 }}>
        <Spin spinning={loading}>
          <div ref={videoContainerRef} style={{ minHeight: 300 }} />
        </Spin>
      </div>
    </>
  );
};
