import { useCreation, useRequest, useSafeState } from 'ahooks';
import { getWxLiveStreamChannelSource } from '@/services/live_stream/wx';
import type { WxLiveStreamDetail } from '@/services/live_stream/wx/type';
import { CHANNEL_COUNT_TYPE } from '@/pages/live/report/weixin/detail/const.var';
import CommonPieChart from '@/pages/live/report/weixin/components/detail/Data/watch-profile-chart/CommonPieChart';
import { Flex, Spin } from '@antd';
import { useEffect, useRef } from 'react';
import ChartCore from '@/components/ChartCore';
import { isEmpty } from 'lodash';
import { dict } from '@/hooks/useChangeLocale';

const TOP_LEVEL = '6'; //顶层指标

export type ChannelIndirectorItem = {
  type: string;
  dataVal: string;
  typeVal: string;
  parentType?: string;
  parentTypeVal?: string;
};

const Chart = ({ type, roomId }: { liveStreamData?: WxLiveStreamDetail; type: CHANNEL_COUNT_TYPE; roomId: string }) => {
  const [secondTitle, setSecondTitle] = useSafeState<string>();

  const { data: indicators = [], loading } = useRequest(
    async () => {
      const data = await getWxLiveStreamChannelSource({
        roomId,
        type,
      });
      return (JSON.parse(data) || []) as ChannelIndirectorItem[];
    },
    { refreshDeps: [roomId, type] },
  );

  const topIncators = useCreation(
    () =>
      indicators
        .filter((v) => v.type == TOP_LEVEL)
        .map((item) => ({
          name: item.typeVal,
          value: Number(item.dataVal),
        }))
        .sort((v1, v2) => v2.value - v1.value),

    [indicators],
  );

  useEffect(() => setSecondTitle(topIncators?.[0]?.name), [topIncators]);

  const secondIncators = useCreation(
    () =>
      indicators
        .filter((v) => v.parentTypeVal == secondTitle)
        .map((item) => ({
          name: item.typeVal,
          value: Number(item.dataVal),
        })),

    [indicators, secondTitle],
  );

  const chartRef = useRef({ currentIndex: 0 });

  const onChartReady = (instance) => {
    //设置高亮
    const setHightlight = (index) => {
      instance.dispatchAction({
        type: 'downplay',
        seriesIndex: 0,
        dataIndex: chartRef.current.currentIndex,
      });

      instance.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: index,
      });
      chartRef.current.currentIndex = index;
    };

    setHightlight(0);
    instance.on('mouseover', (e) => {
      setSecondTitle(e.name);
      setHightlight(topIncators.findIndex((v) => v.name == e.name));
    });
  };

  return (
    <Flex justify="space-around" style={{ alignItems: 'center' }}>
      <div style={{ flex: 1 }}>
        <CommonPieChart loading={loading} data={topIncators} onChartReady={onChartReady} />
      </div>
      <div style={{ flex: 1 }}>
        <SecondChannelBarChart data={secondIncators} loading={loading} title={secondTitle} />
      </div>
    </Flex>
  );
};

const SecondChannelBarChart = ({ data, loading, title }: { data: AnyObject[]; loading: boolean; title?: string }) => {
  const sum = data.reduce((a, b) => a + b.value, 0) || 1;

  const options = useCreation(
    () => ({
      title: { text: title, left: 'center' },
      tooltip: {
        trigger: 'axis',
        formatter: function (params) {
          let tipStr = `${params[0].name}<br/>`;
          params.forEach(function (item) {
            const percent = `${((item.value / sum) * 100).toFixed(2)}%`; // 计算百分比
            tipStr += ` ${item.value}${dict('STATIST_UNIT_REN')} (${percent})`;
          });
          return tipStr;
        },
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {},
      xAxis: {
        type: 'value',
      },
      yAxis: {
        type: 'category',
        offset: 10,
        boundaryGap: ['20%', '20%'],
        inverse: true,
        data: data.map((item) => item.name),
      },
      grid: {
        containLabel: true,
        top: '30px',
        left: '50px',
      },
      series: [
        {
          type: 'bar',
          label: {
            show: true,
            formatter: (params) => `${((params.value / sum) * 100).toFixed(2)}%`,
            offset: [15, 0],
          },
          realtimeSort: true,
          data: data.map((item) => item.value),
        },
      ],
    }),
    [data],
  );

  return (
    <Spin spinning={loading}>
      <ChartCore option={options} empty={isEmpty(data)} style={{ height: 350 }} />
    </Spin>
  );
};

export default Chart;
