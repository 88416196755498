import { useAppContext } from '@/contexts/AppContext';
import { dict } from '@/hooks/useChangeLocale';
import { UsageScopeEnum } from '@/pages/system/keyword/const.var';
import { getKeywordCategoryList } from '@/services/keyword_category';
import { getCommentTotal } from '@/services/live_comment';
import { DateUtils } from '@/utils/dateUtil';
import { Format } from '@/utils/format';
import { Menu, type MenuProps } from '@antd';
import { useCreation, useRequest } from 'ahooks';
import { EventEmitter } from 'ahooks/lib/useEventEmitter';
import { get, isUndefined } from 'lodash';
import React, { useEffect, useState } from 'react';

type MenuItem = Required<MenuProps>['items'][number];

export const Unclassifyied = 'unclassifyied';
function getItem(label: React.ReactNode, key: React.Key, isAll: boolean, icon?: React.ReactNode): MenuItem {
  return {
    key,
    icon,
    label,
    style: {
      paddingLeft: isAll ? undefined : 30,
      width: 200,
    },
  } as MenuItem;
}
const LeftNav = ({
  event$,
  roomId,
  filter,
}: {
  event$: EventEmitter<EmitterObject>;
  roomId: string;
  filter: RequestSearchParams;
}) => {
  const { user } = useAppContext();

  const [categoryIds, setCaterogyIds] = useState<string>();
  const [queryAll, setQueryAll] = useState(false);

  //加载关键词分类
  const { data: keywordCategoryList } = useRequest(
    async () => {
      const keywordCategoryList = await getKeywordCategoryList({
        bid: user?.bid,
        type: UsageScopeEnum.BulletChat,
        disabled: 0, //有效的
      });
      event$.emit({ action: 'SET_CATEGORY_LIST', value: keywordCategoryList });
      setCaterogyIds(keywordCategoryList.map((item) => item.id).join(',')); //设置默认关键词
      setQueryAll(true);
      return keywordCategoryList;
    },
    {
      refreshDeps: [user?.bid],
    },
  );

  //加载统计数据
  const { data: keywordCategoryListCountMap } = useRequest(
    async () => {
      if (!keywordCategoryList) return undefined;
      const [startTime, endTime] = DateUtils.convertDateTimeRangeStr(filter.publishTime);
      const { sumCount, categoryTotal, unclassifiedCount } = await getCommentTotal({
        categoryIds: (keywordCategoryList ?? []).map((v) => v.id).join(','),
        roomId,
        bid: user?.bid,
        startTime,
        endTime,
      });
      const countMap = categoryTotal.reduce((map, cur) => {
        map[cur.categoryId] = cur.count;
        return map;
      }, {});
      return {
        all: sumCount,
        notMatch: unclassifiedCount,
        ...countMap,
      };
    },
    { refreshDeps: [keywordCategoryList, roomId, user?.bid, filter] },
  );

  const getNavItemTitle = (title, count) => {
    if (count)
      return (
        <div title={dict('LIVE_COMMENT_NUMBER', { count })}>
          {title}（{Format.toFirendlyNumText(count)}）
        </div>
      );

    return <div title={dict('NO_COMMENT')}>{title}</div>;
  };

  const items: MenuProps['items'] = useCreation(() => {
    return [
      getItem(
        getNavItemTitle(dict('ALL'), get(keywordCategoryListCountMap, 'all')),
        `${keywordCategoryList?.map((item) => item.id).join(',')}` + `_${true}`,
        true,
      ),
      ...(keywordCategoryList || []).map((v) =>
        getItem(getNavItemTitle(v.name, get(keywordCategoryListCountMap, v.id)), `${v.id}_${false}`, false),
      ),
      getItem(
        getNavItemTitle(dict('UNCLASSIFIED'), get(keywordCategoryListCountMap, 'notMatch')),
        `${Unclassifyied}_${false}`,
        false,
      ),
    ];
  }, [keywordCategoryList, keywordCategoryListCountMap]);

  //通知列表更新
  useEffect(() => {
    if (!isUndefined(categoryIds)) {
      event$.emit({ action: 'REFRESH_TABLE', value: { queryAll, categoryIds } });
    }
  }, [categoryIds, queryAll]);

  return (
    <Menu
      className="page-left-nav"
      selectedKeys={[`${categoryIds}_${queryAll}`]}
      onClick={(e) => {
        const [categoryIds, queryAll] = e.key.split('_');
        setCaterogyIds(categoryIds);
        setQueryAll(queryAll == 'true');
      }}
      items={items}
    />
  );
};

export default LeftNav;
