import { EmojiImgText } from '@/components/EmojiImg';
import { Play } from '@/icons';
import { getCommentList } from '@/services/live_comment';
import type { HighlightTime, LiveStreamData } from '@/services/live_stream/all/type';
import { DateUtils } from '@/utils/dateUtil';
import { Empty, Space, Spin } from '@antd';
import { useBoolean, useRequest, useSafeState } from 'ahooks';
import { EventEmitter } from 'ahooks/lib/useEventEmitter';
import dayjs, { Dayjs } from 'dayjs';
import { get, isEmpty, isUndefined } from 'lodash';
import React, { useEffect, useRef } from 'react';
import type { VideoEventType } from '.';
import style from './index.less';
import { dict } from '@/hooks/useChangeLocale';

type HightLightTimeListProps = {
  detail?: LiveStreamData;
  event$: EventEmitter<VideoEventType>;
  list?: HighlightTime[];
};
export const HightLightTimeList: React.FC<HightLightTimeListProps> = ({ detail, event$, list }) => {
  return (
    <Spin spinning={isUndefined(list)}>
      {isEmpty(list) ? (
        <Empty />
      ) : (
        <div className={style.hightLightTimeWrap}>
          {list?.map((item, index) => {
            return (
              <div key={index}>
                <div style={{ width: '10%' }}>{DateUtils.format(item?.startTime, 'HH:mm')}</div>
                <div title={item.title} style={{ width: 160 }}>
                  {item.reason}
                </div>
                <div style={{ width: '8%' }}>
                  <Space>
                    <a
                      onClick={() => {
                        event$.emit({
                          action: 'updateVideoPlayTime',
                          value: DateUtils.diffIn(detail?.liveStartTime, item.startTime, 'seconds'),
                        });
                      }}
                    >
                      <Play
                        color="blue"
                        style={{ fontSize: 20, paddingTop: 5 }}
                        title={dict('LIVE_CLICK_PLAY_HIGH_LIGHT')}
                      />
                    </a>
                  </Space>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </Spin>
  );
};

type DanmuListProps = {
  roomId: string;
  detail?: LiveStreamData;
  event$: EventEmitter<VideoEventType>;
};
const timeToClassKey = (time: Dayjs) => `lpm_${time.format('hhmmss')}`;
export const DanmuList: React.FC<DanmuListProps> = ({ roomId, detail, event$ }) => {
  const [autoScroll, { setTrue: enableAutoScroll, setFalse: disableAutoScroll }] = useBoolean(true);
  const [playTime, setPlayTime] = useSafeState<number>();
  const containerRef = useRef<HTMLDivElement>(null);

  event$.useSubscription(({ action, value }) => {
    if (action == 'updatePlayTime') setPlayTime(value);
  });

  //弹幕条数
  const { data: list, loading } = useRequest(
    async () => {
      if (isEmpty(detail)) return [];
      return getCommentList({
        roomId,
        startTime: DateUtils.formatDateTime(detail?.liveStartTime),
        endTime: DateUtils.formatDateTime(detail?.liveEndTime),
      });
    },
    { refreshDeps: [roomId, detail] },
  );

  //根据播放时间滚动评论
  useEffect(() => {
    if (!playTime || !autoScroll) return;
    const elemenSelector = timeToClassKey(dayjs(detail?.liveStartTime).add(playTime, 'seconds'));
    const $dom = document.querySelector(`.${elemenSelector}`);

    if ($dom) {
      $dom?.parentElement?.scrollTo({
        top: get($dom, 'offsetTop'),
        behavior: 'smooth', // 平滑过渡效果
      });
    }
  }, [playTime, autoScroll]);

  return (
    <Spin spinning={loading}>
      {isEmpty(list) ? (
        <Empty />
      ) : (
        <div
          ref={containerRef}
          className={style.commentWrap}
          onMouseOver={disableAutoScroll}
          onMouseLeave={enableAutoScroll}
        >
          {list?.map((data, index) => {
            return (
              <div key={index} className={timeToClassKey(dayjs(data?.createTime))}>
                <div>{DateUtils.format(data?.createTime, 'HH:mm:ss')}</div>
                <div title={data.content} style={{ wordBreak: 'break-all' }}>
                  <EmojiImgText content={`${data?.nickName}：${data?.content}`} />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </Spin>
  );
};
