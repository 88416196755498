import { EmojiImgText } from '@/components/EmojiImg';
import { getCommentList } from '@/services/live_comment';
import type { WxLiveStreamData } from '@/services/live_stream/wx/type';
import { DateUtils } from '@/utils/dateUtil';
import { Empty, Spin } from '@antd';
import { useBoolean, useRequest, useSafeState } from 'ahooks';
import { EventEmitter } from 'ahooks/lib/useEventEmitter';
import dayjs, { Dayjs } from 'dayjs';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useRef } from 'react';
import type { VideoEventType } from '.';
import style from './index.less';

type DanmuListProps = {
  roomId: string;
  detail?: WxLiveStreamData;
  event$: EventEmitter<VideoEventType>;
};
const timeToClassKey = (time: Dayjs) => `lpm_${time.format('hhmmss')}`;
export const DanmuList: React.FC<DanmuListProps> = ({ roomId, detail, event$ }) => {
  const [autoScroll, { setTrue: enableAutoScroll, setFalse: disableAutoScroll }] = useBoolean(true);
  const [playTime, setPlayTime] = useSafeState<number>();
  const containerRef = useRef<HTMLDivElement>(null);

  event$.useSubscription(({ action, value }) => {
    if (action == 'updatePlayTime') setPlayTime(value as number);
  });

  //弹幕条数
  const { data: list, loading } = useRequest(
    async () => {
      if (isEmpty(detail)) return [];
      return getCommentList({
        roomId,
        startTime: DateUtils.formatDateTime(detail?.liveStartTime),
        endTime: DateUtils.formatDateTime(detail?.liveEndTime),
      });
    },
    { refreshDeps: [roomId, detail] },
  );

  //根据播放时间滚动评论
  useEffect(() => {
    if (!playTime || !autoScroll) return;
    const elemenSelector = timeToClassKey(dayjs(detail?.liveStartTime).add(playTime, 'seconds'));
    const $dom = document.querySelector(`.${elemenSelector}`);

    if ($dom) {
      $dom?.parentElement?.scrollTo({
        top: get($dom, 'offsetTop'),
        behavior: 'smooth', // 平滑过渡效果
      });
    }
  }, [playTime, autoScroll]);

  return (
    <Spin spinning={loading}>
      {isEmpty(list) ? (
        <Empty style={{ marginTop: 30 }} />
      ) : (
        <div
          ref={containerRef}
          className={style.commentWrap}
          onMouseOver={disableAutoScroll}
          onMouseLeave={enableAutoScroll}
        >
          {list?.map((data, index) => {
            return (
              <div key={index} className={timeToClassKey(dayjs(data?.createTime))}>
                <div>{DateUtils.format(data?.createTime, 'HH:mm:ss')}</div>
                <div title={data.content} style={{ wordBreak: 'break-all' }}>
                  <EmojiImgText content={`${data?.nickName}：${data?.content}`} />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </Spin>
  );
};
