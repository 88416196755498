import ChartCore, { ChartCoreProps } from '@/components/ChartCore';
import { dict } from '@/hooks/useChangeLocale';
import { useCreation } from 'ahooks';
import { Spin } from '@antd';
import { isEmpty } from 'lodash';

export const getAgeLabel = (name: string) => {
  if (name.endsWith('-')) return dict('LIVE_STATIST_AGE_ABOUT', { name: name.replace('_', '') });
  return /\d/.test(name) ? dict('LIVE_STATIST_AGE', { age: name }) : name;
};

export const getAgeTooltip = (item: { name: string; value: number }, sumValue: number) => {
  return `${item.name}: ${((item.value / sumValue) * 100).toFixed(1)}%`;
};

const Chart = ({
  data,
  formatName,
  formatTooltip,
  formatLabel,
  loading,
  ...props
}: {
  data: AnyObject;
  formatName?: (name) => string;
  formatTooltip?: (params) => string;
  formatLabel?: (params) => string;
  loading: boolean;
} & Omit<ChartCoreProps, 'option'>) => {
  const options = useCreation(
    () => ({
      legend: {
        left: 'center',
      },
      tooltip: {
        trigger: 'item',
        formatter: formatTooltip || `{b}<br/>{c}${dict('STATIST_UNIT_REN')} {d}%`,
      },
      title: {
        text: dict('LIVE_VIEW_USER_COUNT'),
        subtext: data.reduce((total, v) => total + v.value, 0),
        left: 'center',
        top: 'center',
      },
      series: [
        {
          type: 'pie',
          radius: ['40%', '60%'],
          percentPrecision: 1,
          label: {
            show: true,
            formatter: formatLabel || `{b} {c}${dict('STATIST_UNIT_REN')} {d}%`,
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
          data: formatName
            ? data.map((item) => ({
                ...item,
                name: formatName(item.name),
              }))
            : data,
        },
      ],
    }),
    [data],
  );

  return (
    <Spin spinning={loading}>
      <ChartCore {...props} option={options} empty={isEmpty(data)} style={{ height: 300 }} notMerge />
    </Spin>
  );
};

export default Chart;
