import { StatisticCardPanel } from '@/components/StatisticCard';
import { Format } from '@/utils/format';
import { useCreation, useRequest } from 'ahooks';
import { get, omit } from 'lodash';
import React from 'react';
import { LiveIndicatorTitle } from '@/pages/live/report/components/LiveIndicatorTitle';
import { getKsLiveStreamPageData } from '@/services/live_stream/ks';
import { dict } from '@/hooks/useChangeLocale';
import type { KsLiveStreamPageDataParams } from '@/services/live_stream/ks/type';

type DataStatisticCardProps = {
  searchPrams: AnyObject;
};

export const StatisticCardSimple: React.FC<DataStatisticCardProps> = ({ searchPrams }) => {
  const params = useCreation(() => omit(searchPrams, ['liveTimeStart', 'liveTimeStart']), [searchPrams]);
  const { data: item, loading } = useRequest(() => getKsLiveStreamPageData(params as KsLiveStreamPageDataParams), {
    refreshDeps: [JSON.stringify(params)],
  });

  return (
    <StatisticCardPanel
      loading={loading}
      items={[
        {
          title: <LiveIndicatorTitle title={dict('LIVE_TOTAL')} tip={dict('LIVE_STATIST_LIVE_NUM_TIP')} />,
          value: Format.toAmountNumber(item?.sessionNum),
          suffix: dict('STATIST_UNIT_CHANG'),
        },

        {
          title: (
            <LiveIndicatorTitle
              title={dict('LIVE_STATIST_LIVE_DURATION')}
              tip={dict('LIVE_STATIST_LIVE_DURATION_TIP')}
            />
          ),
          value: Format.toAmountNumber(item?.liveDuration),
          mValues: [
            { value: Math.floor(get(item, 'liveDuration', 0) / 60 / 60), suffix: dict('STATIST_UNIT_HOUR') },
            { value: Math.floor((get(item, 'liveDuration', 0) / 60) % 60), suffix: dict('STATIST_UNIT_MIN') },
          ],
        },
        {
          title: (
            <LiveIndicatorTitle title={dict('LIVE_ACCUMULATED_VIEWER_TOTAL')} tip={dict('LIVE_STATIST_VIEW_NUM_TIP')} />
          ),
          value: Format.toAmountNumber(item?.viewNum),
          suffix: dict('STATIST_UNIT_REN'),
        },

        {
          title: <LiveIndicatorTitle title={dict('LIVE_STATIST_CLUE_NUM')} tip={dict('LIVE_STATIST_CLUE_NUM_TIP')} />,
          value: Format.toAmountNumber(item?.clueNum),
          suffix: dict('STATIST_UNIT_GE'),
        },
        {
          title: dict('CLUE_VOMUME_MOUNTING_RATE'),
          value: Format.toAmountNumber(item?.leadsRate),
          suffix: '%',
        },
      ]}
    />
  );
};
