import FunnelChart from './data-chart/FunnelChart';
import LineChart from './data-chart/LineChart';
import PieChart from './data-chart/PieChart';

import ClueAnalysisPieChart from './clue-analysis-chart/PieChart';
import ClueAnalysisBarChart from './clue-analysis-chart/BarChart';

import AgePieChart from './watch-profile-chart/AgePieChart';
import GenderPieChart from './watch-profile-chart/GenderPieChart';
import Top10Bar from './watch-profile-chart/Top10Bar';

import type { DyClueAnalysisVO, LiveStreamDetail, LiveWatchProfilData } from '@/services/live_stream/dy/type';
import { ProCard } from '@ant-design/pro-components';
import { useSafeState } from 'ahooks';
import { Radio } from '@antd';
import { Blank } from '@/components/PageCard';
import { headerFormCardProps } from '@/constants/const';
import { dict } from '@/hooks/useChangeLocale';

type DataProps = {
  roomId: string;
  streamData?: LiveStreamDetail;
  profileaData?: LiveWatchProfilData;
  dyClueAnalysisData?: DyClueAnalysisVO;
};
type RegionUnitType = 'city' | 'province';
type CountUnitType = 'tool' | 'toolPv';
const DataChart: React.FC<DataProps> = ({ profileaData, streamData, dyClueAnalysisData, roomId }) => {
  const [regionUnit, setRegionUnit] = useSafeState<RegionUnitType>('city');
  const [countUnit, setCountUnit] = useSafeState<CountUnitType>('tool');

  return (
    <>
      <ProCard.Group gutter={[16, 16]} wrap>
        <ProCard {...headerFormCardProps} colSpan={24} title={dict('TREND_ANALYSIS')}>
          <LineChart roomId={roomId} />
        </ProCard>
      </ProCard.Group>
      <ProCard.Group gutter={[16, 16]} wrap title={dict('CLUE_ANALYSIS')}>
        <ProCard {...headerFormCardProps} colSpan={12} title={dict('CLUE_TRAFFIC_SOURCCE')}>
          <ClueAnalysisPieChart
            dyClueAnalysisData={dyClueAnalysisData}
            type="traffic"
            name={dict('CLUE_TRAFFIC_SOURCCE')}
          />
        </ProCard>

        <ProCard
          {...headerFormCardProps}
          colSpan={12}
          title={dict('AREA_DISTRIBUTION')}
          extra={
            <Radio.Group
              size="small"
              optionType="button"
              buttonStyle="solid"
              value={regionUnit}
              options={[
                { label: dict('CITY'), value: 'city' },
                { label: dict('PROVINCE'), value: 'province' },
              ]}
              onChange={(e) => setRegionUnit(e.target.value)}
            />
          }
        >
          <ClueAnalysisBarChart dyClueAnalysisData={dyClueAnalysisData} type={regionUnit} />
        </ProCard>

        <ProCard
          {...headerFormCardProps}
          colSpan={12}
          title={dict('SEGMENTED_SCENE_ANALYSIS')}
          extra={
            <Radio.Group
              size="small"
              optionType="button"
              buttonStyle="solid"
              value={countUnit}
              options={[
                { label: dict('PEOPLE_COUNT'), value: 'tool' },
                { label: dict('TIME_COUNT'), value: 'toolPv' },
              ]}
              onChange={(e) => setCountUnit(e.target.value)}
            />
          }
        >
          <ClueAnalysisBarChart dyClueAnalysisData={dyClueAnalysisData} type={countUnit} showPercent />
        </ProCard>

        <ProCard {...headerFormCardProps} colSpan={12} title={dict('RESIDENCE_DISTRIBUTION')}>
          <ClueAnalysisBarChart dyClueAnalysisData={dyClueAnalysisData} type="watchDuration" showPercent />
        </ProCard>
      </ProCard.Group>
      <Blank />
      <ProCard.Group gutter={[16, 16]} wrap title={dict('TRAFFIC_ANALYSIS')}>
        <ProCard {...headerFormCardProps} colSpan={12} title={dict('LIVE_STATIST_AUDIENCE_TRAFFIC_SOURCE')}>
          <PieChart liveWatchSourceVo={streamData?.liveWatchSourceVO} />
        </ProCard>

        <ProCard {...headerFormCardProps} colSpan={12} title={dict('TRAFFIC_CONVERSION')}>
          <FunnelChart liveConvertVO={streamData?.liveConvertVO} />
        </ProCard>
      </ProCard.Group>
      <Blank />
      <ProCard.Group gutter={[16, 16]} wrap title={dict('USER_PROFILE_ANALYSIS')}>
        <ProCard {...headerFormCardProps} colSpan={12} title={dict('GENDER_DISTRIBUTION')}>
          <GenderPieChart liveStreamData={streamData} data={profileaData?.gender} />
        </ProCard>
        <ProCard {...headerFormCardProps} colSpan={12} title={dict('AGE_DISTRIBUTION')}>
          <AgePieChart liveStreamData={streamData} data={profileaData?.age} />
        </ProCard>

        <ProCard {...headerFormCardProps} colSpan={12} title={dict('CITY_TOP_10')}>
          <Top10Bar data={profileaData?.city} />
        </ProCard>

        <ProCard {...headerFormCardProps} colSpan={12} title={dict('PROVINCE_TOP_10')}>
          <Top10Bar data={profileaData?.province} />
        </ProCard>
      </ProCard.Group>
    </>
  );
};

export default DataChart;
