import { StatisticCardPanel, StatisticItem } from '@/components/StatisticCard';
import { Format } from '@/utils/format';
import { indicatorMap, LiveIndicatorTitle } from '@/pages/live/report/components/LiveIndicatorTitle';
import { DateUtils } from '@/utils/dateUtil';
import type { WxLiveStreamDetail, WxLiveStreamTotalTrendVO } from '@/services/live_stream/wx/type';
import { ProCard } from '@ant-design/pro-components';
import { headerFormCardProps } from '@/constants/const';
import { dict } from '@/hooks/useChangeLocale';

type DataStatisticCardProps = {
  liveData?: WxLiveStreamDetail;
  totalTrendData?: WxLiveStreamTotalTrendVO;
};

const DataStatisticCard = ({ liveData, totalTrendData }: DataStatisticCardProps) => {
  const loading = !liveData;
  const audienceItems: StatisticItem[] = [
    {
      title: <LiveIndicatorTitle type="lpScreenLiveUserRealtime" />, // 实时在线人数
      value: Format.toAmountNumber(liveData?.lpScreenLiveUserRealtime),
      suffix: dict('STATIST_UNIT_REN'),
    },
    {
      title: <LiveIndicatorTitle type="lpScreenLiveMaxWatchUvByMinute" />, // 最高在线人数
      value: Format.toAmountNumber(liveData?.lpScreenLiveMaxWatchUvByMinute),
      suffix: dict('STATIST_UNIT_REN'),
    },

    {
      title: <LiveIndicatorTitle type="lpScreenLiveWatchUv" />, // 累积观看人数
      value: Format.toAmountNumber(liveData?.lpScreenLiveWatchUv),
      suffix: dict('STATIST_UNIT_REN'),
    },

    {
      title: <LiveIndicatorTitle title={dict('CUMULATIVE_WATCH_PV')} />, // 累计观看人次
      value: Format.toAmountNumber(liveData?.cumulativeWatchPv),
      suffix: dict('STATIST_UNIT_CI'),
    },

    {
      title: <LiveIndicatorTitle type="lpScreenLiveAvgWatchDuration" />, // 人均观看时长
      mValues: DateUtils.formatSecondsToArray(liveData?.lpScreenLiveAvgWatchDuration) || [
        {
          value: '-',
          suffix: dict('STATIST_UNIT_SEC'),
        },
      ],
    },

    {
      title: <LiveIndicatorTitle type="lpScreenLiveAvgOnlineUvByRoom" />, // 平均在线人数
      value: Format.toAmountNumber(liveData?.lpScreenLiveAvgOnlineUvByRoom),
      suffix: dict('STATIST_UNIT_REN'),
    },

    {
      title: <LiveIndicatorTitle title={dict('LP_SCREEN_LIVE_SHOW_UV')} />, // 直播曝光人数
      value: Format.toAmountNumber(liveData?.lpScreenLiveShowUv),
      suffix: dict('STATIST_UNIT_REN'),
    },

    // {
    //   title: <LiveIndicatorTitle title={dict('ADD_FOLLOW')} tip={indicatorMap.lpScreenLiveFollowUv.tip} />, //新增关注
    //   value: Format.toAmountNumber(liveData?.cumulativeNewFollowUv),
    //   suffix: dict('STATIST_UNIT_REN'),
    // },
    // {
    //   title: dict('TOTAL_HEAT'), //总热度
    //   value: Format.toAmountNumber(liveData?.cumulativeHotQuota),
    // },
  ];

  const interactionItems = [
    {
      title: <LiveIndicatorTitle title={dict('ADD_FOLLOW')} />,
      value: Format.toAmountNumber(liveData?.cumulativeNewFollowUv),
      suffix: dict('STATIST_UNIT_REN'), //新增关注
    },

    {
      title: <LiveIndicatorTitle type="lpScreenLiveFansClubJoinUv" />, // 加入粉丝团人数
      value: Format.toAmountNumber(liveData?.lpScreenLiveFansClubJoinUv),
      suffix: dict('STATIST_UNIT_REN'),
    },
    {
      title: <LiveIndicatorTitle type="lpScreenLiveCommentUv" />, // 评论人数
      value: Format.toAmountNumber(liveData?.lpScreenLiveCommentUv),
      suffix: dict('STATIST_UNIT_REN'),
    },

    {
      title: <LiveIndicatorTitle type="lpScreenLiveCommentCount" />, // 评论次数
      value: Format.toAmountNumber(liveData?.lpScreenLiveCommentCount),
      suffix: dict('STATIST_UNIT_CI'),
    },

    {
      //分享直播间人数
      title: (
        <LiveIndicatorTitle title={dict('SHARE_LIVE_ROOM_USER_COUNT')} tip={indicatorMap.lpScreenLiveShareUv.tip} />
      ),
      value: Format.toAmountNumber(liveData?.lpScreenLiveShareUv),
      suffix: dict('STATIST_UNIT_REN'),
    },
    {
      //分享直播间次数
      title: (
        <LiveIndicatorTitle title={dict('SHARE_LIVE_ROOM_TIME_COUNT')} tip={indicatorMap.lpScreenLiveShareCount.tip} />
      ),
      value: Format.toAmountNumber(liveData?.lpScreenLiveShareCount),
      suffix: dict('STATIST_UNIT_CI'),
    },

    {
      title: <LiveIndicatorTitle title={dict('GIFT_GIVE_USER_COUNT')} />, //送礼人数
      value: Format.toAmountNumber(liveData?.cumulativeRewardUv),
    },

    {
      title: <LiveIndicatorTitle title={dict('TOTAL_HEAT')} />, //总热度
      value: Format.toAmountNumber(liveData?.cumulativeHotQuota),
    },

    {
      title: <LiveIndicatorTitle title={dict('GIFT_GIVE_HEAT')} />, //送礼热度
      value: Format.toAmountNumber(liveData?.cumulativeRewardAmountInHeatAnchor),
    },
    {
      title: <LiveIndicatorTitle type="lpScreenLiveLikeCount" />, // 点赞次数
      value: Format.toAmountNumber(liveData?.lpScreenLiveLikeCount),
      suffix: dict('STATIST_UNIT_CI'),
    },
  ];

  // const trendItems = [
  //   {
  //     title: <LiveIndicatorTitle type="lpScreenLiveAvgOnlineUvByRoom" />, // 平均在线人数
  //     value: Format.toAmountNumber(totalTrendData?.averageOnlineWatchUv),
  //     suffix: dict('STATIST_UNIT_REN'),
  //   },
  //   {
  //     title: <LiveIndicatorTitle title={dict('ENTER_ROOM_USER_COUNT')} />, //进房人数
  //     value: Format.toAmountNumber(totalTrendData?.newWatchUv),
  //     suffix: dict('STATIST_UNIT_REN'),
  //   },
  //   {
  //     title: dict('LEAVE_ROOM_USER_COUNT'), //离开人数
  //     value: Format.toAmountNumber(totalTrendData?.leavedWatchUv),
  //     suffix: dict('STATIST_UNIT_REN'),
  //   },
  // ];

  const leadGeneratalItems = [
    {
      title: dict('TOTAL_LEADS'), //留资总数
      value: Format.toAmountNumber(liveData?.leadsNum),
    },

    {
      title: dict('EXPOSURE_UV'), //气泡曝光人数
      value: Format.toAmountNumber(liveData?.exposureUv),
      suffix: dict('STATIST_UNIT_REN'),
    },

    {
      title: dict('EXPOSURE_CLICK_UV'), //气泡点击人数
      value: Format.toAmountNumber(liveData?.clickUv),
      suffix: dict('STATIST_UNIT_REN'),
    },

    {
      title: (
        <LiveIndicatorTitle
          title={dict('EXPOSURE_CONVISION_RATE')}
          tip={`${dict('EXPOSURE_CLICK_UV')}/${dict('EXPOSURE_UV')}`}
        />
      ), //曝光点击转化率
      value: Format.toAmountNumber(liveData?.conversionRate),

      suffix: '%',
    },
  ];

  return (
    <ProCard.Group gutter={[16, 16]} wrap>
      <ProCard {...headerFormCardProps} title={dict('DATA_OVERVIEW')}>
        <StatisticCardPanel loading={loading} items={audienceItems} columns={5} />
      </ProCard>
      <ProCard {...headerFormCardProps} title={dict('LIVE_STATIST_INTERACTIVE_DATA')}>
        <StatisticCardPanel loading={loading} items={interactionItems} columns={5} />
      </ProCard>
      <ProCard {...headerFormCardProps} title={dict('LEAD_GENERATION')}>
        <StatisticCardPanel loading={loading} items={leadGeneratalItems} columns={5} />
      </ProCard>
    </ProCard.Group>
  );
};

export default DataStatisticCard;
