import { Spin } from '@antd';
import { useRequest } from 'ahooks';
import DataStatisticCard from './DataStatisticCard';
import DataChart from './DataChart';
import DataList from './DataList';

import {
  getDyClueAnalysisData,
  getDyLiveStreamDataDetail,
  getDyLiveWatchProfile,
  getDyShortVideoList,
} from '@/services/live_stream/dy';
import { Blank } from '@/components/PageCard';
import { ProCard } from '@ant-design/pro-components';
import { headerFormCardProps } from '@/constants/const';
import { dict } from '@/hooks/useChangeLocale';

type DataProps = {
  roomId: string;
};
const Data: React.FC<DataProps> = ({ roomId }) => {
  const { data, loading } = useRequest(
    () =>
      Promise.all([
        getDyLiveStreamDataDetail(roomId),
        getDyLiveWatchProfile(roomId),
        getDyShortVideoList(roomId),
        getDyClueAnalysisData(roomId),
      ]),
    { refreshDeps: [roomId] },
  );

  const [streamData, profileaData, dyShortVideoList, dyClueAnalysisData] = data || [];

  return (
    <Spin spinning={loading}>
      <DataStatisticCard liveDataVO={streamData?.liveDataVO} />
      <Blank />
      <DataChart
        roomId={roomId}
        streamData={streamData}
        profileaData={profileaData}
        dyClueAnalysisData={dyClueAnalysisData}
      />
      <Blank />

      <ProCard.Group gutter={[16, 16]} wrap>
        <ProCard {...headerFormCardProps} colSpan={24} title={dict('SATREAMING_SHORT_VIDEO')} boxShadow={false}>
          <DataList list={dyShortVideoList} />
        </ProCard>
      </ProCard.Group>
    </Spin>
  );
};

export default Data;
