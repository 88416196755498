import ChartCore from '@/components/ChartCore';
import { MEDIA_KEY_ENUM } from '@/constants/const';
import { useAppContext } from '@/contexts/AppContext';
import { dict } from '@/hooks/useChangeLocale';
import { getLiveStreamDetailTrend, getLiveStreamTrend } from '@/services/live_stream/all';
import { DATE_FORMAT_STR, DATE_MINI_FORMAT_STR, DateUtils } from '@/utils/dateUtil';
import { Select, Space, Spin } from '@antd';
import { useRequest } from 'ahooks';
import { get } from 'lodash';
import { useState } from 'react';

const getOption = (data, labels, indicator, indicatorOptions, format = 'HH:mm') => {
  return {
    tooltip: {
      trigger: 'axis',
    },
    legend: {},
    grid: {
      top: 30,
      left: 50,
      right: 50,
      bottom: 60,
    },
    dataZoom: data.length > 10 ? [{ maxValueSpan: 15, minValueSpan: 8, start: 90 }] : undefined,
    xAxis: {
      type: 'category',
      boundaryGap: false,
      offset: 10,
      data: labels,
      axisLabel: {
        formatter: function (value) {
          return `${DateUtils.format(value, format)}`;
        },
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: '{value} ',
      },
    },
    series: indicatorOptions
      .filter((v) => indicator.includes(v.value))
      .map(({ label, value }) => ({
        name: label,
        // smooth: true,
        areaStyle: {
          opacity: 0,
        },
        type: 'line',
        data: data.map((v) => v[value]),
      })),
  };
};

enum TimeTypeEnum {
  min1 = 0,
  min5 = 1,
  hour = 2,
}

const tip = dict('LIVE_INDICATOR_TIP');

const defaultIndecator = ['lpScreenLiveShowCount', 'lpScreenLiveWatchUvByMinute', 'lpScreenLiveMaxWatchUvByMinute'];

export const LiveLineChart = ({
  accountId,
  startDate,
  endDate,
}: {
  accountId: Key;
  startDate?: string;
  endDate?: string;
}) => {
  const { user } = useAppContext();

  const indicatorOptions = [
    {
      label: dict('LIVE_TOTAL'),
      value: 'liveTotal',
    },
    {
      label: dict('LIVE_INDICATOR_WATCH_UV'),
      value: 'lpScreenLiveWatchUv',
    },
    {
      label: dict('LP_SCREEN_LIVE_LIKE_COUNT'),
      value: 'lpScreenLiveLikeCount',
    },
    {
      label: dict('LIVE_INDICATOR_COMMENT_COUNT'),
      value: 'lpScreenLiveCommentCount',
    },
    {
      label: dict('LP_SCREEN_LIVE_SHARE_COUNT'),
      value: 'lpScreenLiveShareCount',
    },
  ];
  const [indicator] = useState([
    'liveTotal',
    'lpScreenLiveWatchUv',
    'lpScreenLiveLikeCount',
    'lpScreenLiveCommentCount',
    'lpScreenLiveShareCount',
  ]);

  const { data = {}, loading } = useRequest(
    async () => {
      const list = await getLiveStreamTrend({
        accountId,
        bid: user?.bid,
        startDate,
        endDate,
        mediaId: MEDIA_KEY_ENUM.DOUYIN,
      });

      return list.reduce((obj, cur) => {
        obj[DateUtils.format(cur['liveDate'], DATE_FORMAT_STR)] = cur;
        return obj;
      }, {});
    },
    { refreshDeps: [accountId, startDate, endDate, user?.bid] },
  );

  return (
    <>
      <Spin spinning={loading}>
        <ChartCore
          option={getOption(Object.values(data), Object.keys(data), indicator, indicatorOptions, DATE_FORMAT_STR)}
          notMerge
          style={{ height: 400 }}
        />
      </Spin>
    </>
  );
};

const LiveDetailLineChart = ({ roomId }: { roomId: string }) => {
  const { getEnum } = useAppContext();

  const indicatorOptions = getEnum('LiveDetailTrendEnum').map(({ displayName, name }) => ({
    label: displayName,
    value: name,
  }));

  const [indicator, setIndicator] = useState(defaultIndecator);

  const { data = {}, loading } = useRequest(
    async () => {
      const list = await getLiveStreamDetailTrend({
        roomId,
        timeType: TimeTypeEnum.min1,
      });

      return list.reduce((obj, cur) => {
        obj[DateUtils.format(cur['dimensions'], DATE_MINI_FORMAT_STR)] = cur;
        return obj;
      }, {});
    },
    { refreshDeps: [roomId] },
  );

  return (
    <>
      <Space style={{ display: 'flex', justifyContent: 'right', marginRight: 20 }}>
        <Select
          value={indicator}
          title={tip}
          mode="multiple"
          maxTagCount="responsive"
          options={indicatorOptions}
          onChange={(value) => {
            const valCount = get(value, 'length', 0);
            if (valCount < 1) {
              setIndicator(defaultIndecator);
            } else {
              setIndicator((value as string[]).splice(-3));
            }
          }}
          size="small"
          showSearch={false}
          style={{
            width: 250,
          }}
        />
      </Space>
      <Spin spinning={loading}>
        <ChartCore
          option={getOption(Object.values(data), Object.keys(data), indicator, indicatorOptions)}
          notMerge
          empty={!data}
          style={{ height: 400 }}
        />
      </Spin>
    </>
  );
};

export default LiveDetailLineChart;
