import { StatisticCardPanel } from '@/components/StatisticCard';
import { dict } from '@/hooks/useChangeLocale';
import { getKsLiveStreamDetailData } from '@/services/live_stream/ks';
import { Format } from '@/utils/format';
import { Col, Row, Spin } from '@antd';
import { useRequest } from 'ahooks';

type DataProps = {
  roomId: string;
};
const Data: React.FC<DataProps> = ({ roomId }) => {
  const { data: liveDataVO, loading } = useRequest(() => getKsLiveStreamDetailData(roomId), { refreshDeps: [roomId] });

  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={10}>
          <StatisticCardPanel
            title={dict('LIVE_STATIST_AUDIENCE_DATA')}
            loading={loading}
            items={[
              {
                title: dict('LIVE_ACCUMULATED_VIEWER_TOTAL'),
                value: Format.toAmountNumber(liveDataVO?.lpScreenLiveWatchUv),
                suffix: dict('STATIST_UNIT_REN'),
              },

              {
                title: dict('PEAK_ONLINE_USER'),
                value: Format.toAmountNumber(liveDataVO?.lpScreenLiveMaxWatchUvByMinute),
                suffix: dict('STATIST_UNIT_REN'),
              },
            ]}
          />
        </Col>
        <Col span={14}>
          <StatisticCardPanel
            title={dict('LIVE_STATIST_INTERACTIVE_DATA')}
            loading={loading}
            items={[
              {
                title: dict('LP_SCREEN_LIVE_SHARE_COUNT'),
                value: Format.toAmountNumber(liveDataVO?.lpScreenLiveShareCount),
                suffix: dict('STATIST_UNIT_CI'),
              },

              {
                title: dict('LP_SCREEN_LIVE_LIKE_COUNT'),
                value: Format.toAmountNumber(liveDataVO?.lpScreenLiveLikeCount),
                suffix: dict('STATIST_UNIT_CI'),
              },
              {
                title: dict('LIVE_INDICATOR_COMMENT_COUNT'),
                value: Format.toAmountNumber(liveDataVO?.lpScreenLiveCommentCount),
                suffix: dict('STATIST_UNIT_CI'),
              },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col span={15}>
          <StatisticCardPanel
            title={dict('LIVE_STATIST_CONVERT_DATA')}
            loading={loading}
            items={[
              {
                title: dict('CLUE_COLLECTION_NUM'),
                value: Format.toAmountNumber(liveDataVO?.lpScreenClueUv),
                suffix: dict('STATIST_UNIT_REN'),
              },
              {
                title: dict('GIFT_RECEIVED_NUM'),
                value: Format.toAmountNumber(liveDataVO?.receiveCnt),
              },
              {
                title: dict('GIFT_COLLECTION_FLOW_NUM'),
                value: Format.toAmountNumber(liveDataVO?.receiveAmount, 2),
                suffix: dict('STATIST_UNIT_YUAN'),
              },
            ]}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default Data;
