import { StatisticCardPanel, StatisticItem } from '@/components/StatisticCard';
import { Format } from '@/utils/format';
import { useRequest } from 'ahooks';
import { get, omit } from 'lodash';
import React from 'react';
import { LiveIndicatorTitle } from '@/pages/live/report/components/LiveIndicatorTitle';
import { getDyLiveStreamPageData } from '@/services/live_stream/dy';
import { dict } from '@/hooks/useChangeLocale';

type DataStatisticCardProps = {
  searchPrams: AnyObject;
};

export const StatisticCardSimple: React.FC<DataStatisticCardProps> = ({ searchPrams }) => {
  const { data: item, loading } = useRequest(
    () => {
      const { accountId, ...params } = searchPrams;
      return getDyLiveStreamPageData({
        dateScreen: 0, //累积至今
        accountIds: accountId ? [accountId] : undefined,
        ...omit(params, ['liveTimeStart', 'liveTimeStart']),
      });
    },
    {
      refreshDeps: [searchPrams],
    },
  );

  const items: StatisticItem[] = [
    {
      title: <LiveIndicatorTitle title={dict('LIVE_STATIST_ACCOUNT_NUM')} tip={dict('LIVE_STATIST_ACCOUNT_NUM_TIP')} />,
      value: Format.toAmountNumber(item?.accountNum),
      suffix: dict('STATIST_UNIT_GE'),
    },
    {
      title: <LiveIndicatorTitle title={dict('LIVE_STATIST_LIVE_TOTAL_NUM')} tip={dict('LIVE_STATIST_LIVE_NUM_TIP')} />,
      value: Format.toAmountNumber(item?.sessionNum),
      suffix: dict('STATIST_UNIT_CHANG'),
    },
    {
      title: (
        <LiveIndicatorTitle title={dict('LIVE_STATIST_LIVE_DURATION')} tip={dict('LIVE_STATIST_LIVE_DURATION_TIP')} />
      ),
      value: Format.toAmountNumber(item?.liveDuration),
      mValues: [
        { value: Math.floor(get(item, 'liveDuration', 0) / 60 / 60), suffix: dict('STATIST_UNIT_HOUR') },
        { value: Math.floor((get(item, 'liveDuration', 0) / 60) % 60), suffix: dict('STATIST_UNIT_MIN') },
      ],
    },
    {
      title: <LiveIndicatorTitle title={dict('LIVE_STATIST_VIEW_NUM')} tip={dict('LIVE_STATIST_VIEW_NUM_TIP')} />,
      value: Format.toAmountNumber(item?.viewNum),
      suffix: dict('STATIST_UNIT_REN'),
    },
    {
      title: <LiveIndicatorTitle title={dict('AVG_WATCH_TIME')} tip={dict('LIVE_STATIST_DURATION_AVG_TIP')} />,
      value: Format.toAmountNumber(item?.liveDurationAvg),
      mValues: [{ value: Math.round(get(item, 'liveDurationAvg', 0)), suffix: dict('STATIST_UNIT_SEC') }],
    },

    {
      title: <LiveIndicatorTitle title={dict('LIVE_STATIST_COMMENT_NUM')} tip={dict('LIVE_STATIST_COMMENT_NUM_TIP')} />,
      value: Format.toAmountNumber(item?.commentNum),
      suffix: dict('STATIST_UNIT_CI'),
    },
    {
      title: <LiveIndicatorTitle title={dict('LIVE_STATIST_CLUE_NUM')} tip={dict('LIVE_STATIST_CLUE_NUM_TIP')} />,
      value: Format.toAmountNumber(item?.clueNum),
      suffix: dict('STATIST_UNIT_GE'),
    },
  ];

  return <StatisticCardPanel loading={loading} items={items} />;
};
