import { AccountAvator } from '@/components/AccountAvator';
import { FormCard } from '@/components/PageCard';
import { useAddTagModal } from '@/components/modal/AddTagModal';
import { ROUTE_PATH } from '@/constants/const';
import { AUTH } from '@/constants/permission-code';
import { useAppContext } from '@/contexts/AppContext';
import { useTagColumns } from '@/hooks/tag/useTagColumn';
import { dict } from '@/hooks/useChangeLocale';
import { usePermission } from '@/hooks/usePermission';
import useTableRequest, { getTableColumn } from '@/hooks/useTableRequest';
import { getSoretParams } from '@/pages/live/const.var';
import { LiveIndicatorTitle, indicatorMap } from '@/pages/live/report/components/LiveIndicatorTitle';
import { updateLiveTag } from '@/services/live_stream/all';
import { downloadWxLiveStream, getWxLiveStreamPages } from '@/services/live_stream/wx';
import type { WxLiveStreamPageParams } from '@/services/live_stream/wx/type';
import { DateUtils } from '@/utils/dateUtil';
import { App, Space, Spin, Table, Typography } from '@antd';
import { useCreation, useRequest, useSafeState } from 'ahooks';
import { camelCase } from 'lodash-es';

const { Link } = Typography;

type ListPros = {
  params: RequestSearchParams;
};
const List = ({ params }: ListPros) => {
  const { message } = App.useApp();
  const { getEnum, user } = useAppContext();
  const [downloadParams, setDownloadParams] = useSafeState<RequestSearchParams>({});
  const liveStreamSortEnums = getEnum('LiveStreamSortParamEnum');
  const liveStreamSortFieldMap = useCreation(
    () =>
      liveStreamSortEnums.reduce((map, cur) => {
        const { name } = cur;
        const [, fieldName] = name.split('.');
        map[camelCase(fieldName)] = name;
        return map;
      }, {}),
    [liveStreamSortEnums],
  );

  const { refresh: download, loading: downloadLoading } = useRequest(
    () => downloadWxLiveStream(downloadParams as WxLiveStreamPageParams),
    {
      manual: true,
    },
  );

  const { tableProps, refresh } = useTableRequest(
    ({ pageIndex, pageSize, sorter = { field: 'liveStartTime', order: 'descend' } }) => {
      const downloadParams = {
        ...params,
        tagValueIds: params.tagValueIds?.map((v) => v[1]),
        ...getSoretParams(sorter, liveStreamSortFieldMap),
      };
      setDownloadParams(downloadParams);
      return getWxLiveStreamPages({
        ...downloadParams,
        pageIndex,
        pageSize,
      });
    },
    { refreshDeps: [params], debounceWait: 100 },
  );

  const { run: updateTag, loading: updateTagLoading } = useRequest(
    async (itemId, tagIds) => {
      try {
        await updateLiveTag(itemId, tagIds, user?.bid);
        message.success(dict('OPERATION_SUCCESS'));
        refresh();
      } catch (error) {
        message.error(dict('OPERATION_FAILURE'));
      }
    },
    {
      refreshDeps: [user],
      manual: true,
    },
  );

  const [AddTagModal, showTagModal] = useAddTagModal();

  const { getBtnPermissionConfig } = usePermission();
  const viewAuthConfig = getBtnPermissionConfig(AUTH.LIVE_STREAM.VIEW);
  const hasTags = !getBtnPermissionConfig(AUTH.TAG_MANAGEMENT.PAGE)?.disabled;
  const [tagColumns] = useTagColumns();

  const ROUTE_REPORT_PATH = `/${ROUTE_PATH.LIVE}/${ROUTE_PATH.REPORT}`;

  return (
    <FormCard
      title={dict('LIVE_DATA')}
      style={{ marginBottom: 10 }}
      extra={
        <Spin spinning={downloadLoading}>
          <Link onClick={download} {...getBtnPermissionConfig(AUTH.LIVE_STREAM.DOWNLOAD)}>
            {dict('ACTION_DOWNLOAD_DATA')}
          </Link>
        </Spin>
      }
    >
      <Table
        {...tableProps}
        loading={tableProps?.loading || updateTagLoading}
        scroll={{ x: 2600, y: 500 }}
        columns={
          [
            getTableColumn({ title: dict('DEALER'), dataIndex: 'distributorName', fixed: 'left' }),
            getTableColumn({ title: dict('DEALER_CODE'), dataIndex: 'distributorCode', fixed: 'left' }),
            // getTableColumn({ title: dict('LIVE_ROOM_ID'), dataIndex: 'roomId' }),
            getTableColumn({
              title: dict('LIVE_ACCOUNT'),
              dataIndex: 'name',
              width: 150,
              render: (_, record) => <AccountAvator avaterUrl={record?.avatarUrl} name={record?.name} />,
            }),
            getTableColumn({ title: dict('REGION_AREA'), dataIndex: 'regionName' }),

            // getTableColumn({ title: dict('STORE'), dataIndex: 'storeName' }),
            // getTableColumn({ title: dict('EMPLOYEE'), dataIndex: 'staffName' }),
            // getTableColumn({ title: dict('INFLUENCER'), dataIndex: 'influenceName' }),
            // hasTags
            //   ? getTableColumn({
            //       title: dict('TAG'),
            //       dataIndex: 'tagInfoList',
            //       width: 200,
            //       ellipsis: true,
            //       render: (tagInfoList) =>
            //         isEmpty(tagInfoList) ? '-' : tagInfoList?.map((v) => `${v.tagName}-${v.tagValueName}`).join(','),
            //     })
            //   : null,
            ...(tagColumns as any[]),
            getTableColumn({ title: dict('LIVE_STREAM_THEME'), dataIndex: 'liveStreamCoverTitle', width: 150 }),
            getTableColumn({
              title: dict('LIVE_TIME'),
              dataIndex: 'liveStartTime',
              sorter: true,
              defaultSortOrder: 'descend',
              width: 150,
              render: (v) => DateUtils.formatDateTime(v),
            }),
            getTableColumn({
              title: dict('LIVE_END_TIME'),
              dataIndex: 'liveEndTime',
              sorter: true,
              width: 150,
              render: (v) => DateUtils.formatDateTime(v),
            }),
            getTableColumn({
              title: dict('LIVE_DURATION'),
              dataIndex: 'liveDuration',
              sorter: true,
              width: 150,
              render: (v) => DateUtils.formatDiffrenceTime(v),
            }),
            // 累积观看人数
            getTableColumn({
              title: <LiveIndicatorTitle type="lpScreenLiveWatchUv" />,
              dataIndex: 'lpScreenLiveWatchUv',
              width: 150,
              sorter: true,
            }),
            // 实时在线人数
            getTableColumn({
              title: <LiveIndicatorTitle type="lpScreenLiveUserRealtime" />,
              dataIndex: 'lpScreenLiveUserRealtime',
              width: 150,
              sorter: true,
            }),
            // 最高在线人数
            getTableColumn({
              title: <LiveIndicatorTitle type="lpScreenLiveMaxWatchUvByMinute" />,
              dataIndex: 'lpScreenLiveMaxWatchUvByMinute',
              width: 150,
              sorter: true,
            }),
            // 人均观看时长
            getTableColumn({
              title: <LiveIndicatorTitle type="lpScreenLiveAvgWatchDuration" />,
              dataIndex: 'lpScreenLiveAvgWatchDuration',
              width: 150,
              sorter: true,
              render: (v) => DateUtils.formatDiffrenceTime(v),
            }),
            // 评论次数
            getTableColumn({
              title: <LiveIndicatorTitle type="lpScreenLiveCommentUv" />,
              dataIndex: 'lpScreenLiveCommentUv',
              width: 150,
              sorter: true,
            }),
            // 评论次数
            getTableColumn({
              title: <LiveIndicatorTitle type="lpScreenLiveCommentCount" />,
              width: 150,
              dataIndex: 'lpScreenLiveCommentCount',
              sorter: true,
            }),
            // 点赞次数
            getTableColumn({
              title: <LiveIndicatorTitle type="lpScreenLiveLikeCount" />,
              dataIndex: 'lpScreenLiveLikeCount',
              width: 150,
              sorter: true,
            }),
            // 新增关注
            getTableColumn({
              title: <LiveIndicatorTitle title={dict('ADD_FOLLOW')} tip={indicatorMap.lpScreenLiveFollowUv.tip} />,
              dataIndex: 'cumulativeNewFollowUv',
              width: 150,
              sorter: true,
            }),
            // 分享直播间人数
            getTableColumn({
              title: <LiveIndicatorTitle type="lpScreenLiveShareUv" />,
              width: 150,
              dataIndex: 'lpScreenLiveShareUv',
              sorter: true,
            }),
            // 分享直播间次数
            getTableColumn({
              title: <LiveIndicatorTitle type="lpScreenLiveShareCount" />,
              dataIndex: 'lpScreenLiveShareCount',
              width: 150,
              sorter: true,
            }),
            // 加入粉丝团人数
            getTableColumn({
              title: <LiveIndicatorTitle type="lpScreenLiveFansClubJoinUv" />,
              dataIndex: 'lpScreenLiveFansClubJoinUv',
              width: 150,
              sorter: true,
            }),
            getTableColumn({
              title: dict('TOTAL_HEAT'),
              dataIndex: 'cumulativeHotQuota',
              sorter: true,
            }),
            getTableColumn({
              title: dict('GIFT_GIVE_USER_COUNT'),
              dataIndex: 'cumulativeRewardUv',
              sorter: true,
            }),
            getTableColumn({
              title: dict('GIFT_GIVE_HEAT'),
              dataIndex: 'cumulativeRewardAmountInHeatAnchor',
              sorter: true,
            }),
            // 平均在线人数
            getTableColumn({
              title: <LiveIndicatorTitle type="lpScreenLiveAvgOnlineUvByRoom" />,
              dataIndex: 'averageOnlineWatchUv',
              width: 150,
              sorter: true,
            }),
            getTableColumn({
              title: dict('ENTER_ROOM_USER_COUNT'),
              dataIndex: 'newWatchUv',
              sorter: true,
            }),
            getTableColumn({ title: dict('LEAVE_ROOM_USER_COUNT'), dataIndex: 'leavedWatchUv', sorter: true }),
            getTableColumn({ title: dict('EXPOSURE_UV'), dataIndex: 'exposureUv', width: 150, sorter: true }),
            getTableColumn({ title: dict('EXPOSURE_CLICK_UV'), dataIndex: 'clickUv', width: 150, sorter: true }),
            getTableColumn({
              title: dict('EXPOSURE_CONVISION_RATE'),
              dataIndex: 'conversionRate',
              width: 150,
              sorter: true,
              render: (v) => `${v}%`,
            }),
            getTableColumn({ title: dict('COLLECTED_LEAD_NUM'), dataIndex: 'leadsNum', width: 150, sorter: true }),
            getTableColumn({
              title: dict('ACTION_OPERATE'),
              align: 'center',
              fixed: 'right',
              width: hasTags ? 230 : 160,
              render: (_, record) => {
                const { roomId } = record;
                const url = `${ROUTE_REPORT_PATH}/${ROUTE_PATH.SHIPINHAO}/${ROUTE_PATH.DETAIL}/${roomId}`;

                return (
                  <Space>
                    <Link onClick={() => window.open(`${BASE_PATH}${url}?type=data`)} {...viewAuthConfig}>
                      {dict('ACTION_REPORT')}
                    </Link>
                    <Link onClick={() => window.open(`${BASE_PATH}${url}?type=barrage`)} {...viewAuthConfig}>
                      {dict('BARRAGE')}
                    </Link>
                    <Link onClick={() => window.open(`${BASE_PATH}${url}?type=playhistory`)} {...viewAuthConfig}>
                      {dict('ACTION_REPLAY')}
                    </Link>
                    {hasTags ? (
                      <Link
                        title={dict('ACTION_ADD_NAME', { name: dict('TAG') })}
                        onClick={() => {
                          showTagModal(
                            record?.tagInfoList?.filter((v) => v.type == 'manual').map((v) => [v.tagId, v.tagValId]),
                            record?.tagInfoList?.filter((v) => v.type == 'automatic').map((v) => [v.tagId, v.tagValId]),
                            record.roomId,
                          );
                        }}
                      >
                        {dict('ACTION_ADD_NAME', { name: dict('TAG') })}
                      </Link>
                    ) : null}
                  </Space>
                );
              },
            }),
          ].filter(Boolean) as TableCoumn[]
        }
      />
      <AddTagModal
        onTagSelect={(tagIds, targetId) => {
          updateTag(
            targetId,
            tagIds.map((v) => v[1]),
          );
        }}
      />
    </FormCard>
  );
};

export default List;
