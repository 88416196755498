import useTableRequest, { getTableColumn } from '@/hooks/useTableRequest';
import { Format } from '@/utils/format';
import { Table } from '@antd';
import { ProCard } from '@ant-design/pro-components';
import { headerFormCardProps } from '@/constants/const';
import { dict } from '@/hooks/useChangeLocale';

type IndicatorItem = { name: string; value: number };

export const ProvienceTable = ({ provinceData }: { provinceData: IndicatorItem[] }) => {
  const { tableProps: provinceTableProps } = useTableRequest(
    async () => {
      const items = provinceData.filter((v) => v.value).sort((v1, v2) => v2.value - v1.value);
      const total = items.reduce((total, cur) => total + cur.value, 0);

      return {
        list: items.map((item, index) => ({
          ...item,
          id: index + 1,

          rate: total ? Format.toPercent(Format.toRoundNumber(item.value / total, 4)) : '-',
        })),
      };
    },
    { refreshDeps: [provinceData] },
  );

  return (
    <ProCard title={dict('PROVINCE_DISTRIBUTION')} {...headerFormCardProps}>
      <Table
        {...provinceTableProps}
        size="small"
        columns={[
          getTableColumn({
            title: dict('RANKINGS'),
            dataIndex: 'id',
          }),
          getTableColumn({
            title: dict('PROVINCE'),
            dataIndex: 'name',
          }),
          getTableColumn({
            title: dict('PEOPLE_COUNT'),
            dataIndex: 'value',
          }),
          getTableColumn({
            title: dict('PERCENTAGE'),
            dataIndex: 'rate',
          }),
        ]}
      />
    </ProCard>
  );
};
