import ChartCore from '@/components/ChartCore';
import { dict } from '@/hooks/useChangeLocale';
import type { LiveStreamDetail } from '@/services/live_stream/all/type';
import { Format } from '@/utils/format';
import { useCreation } from 'ahooks';
import { get } from 'lodash';

const Chart = ({ data, liveStreamData }: { data: AnyObject; liveStreamData?: LiveStreamDetail }) => {
  const options = useCreation(
    () => ({
      legend: {
        left: 'center',
      },
      tooltip: {
        formatter: '{b}: {c}%',
      },
      title: {
        text: dict('LIVE_VIEW_USER_COUNT'),
        subtext: Format.toAmountNumber(liveStreamData?.liveDataVO?.lpScreenLiveWatchUv),
        left: 'center',
        top: 'center',
      },
      series: [
        {
          type: 'pie',
          radius: ['40%', '60%'],
          label: {
            show: true,
            formatter: '{b}: {c}%',
          },
          data: [
            { value: get(data, 'male', 0), name: dict('MALE') },
            { value: get(data, 'female', 0), name: dict('FEMALE') },
          ],
        },
      ],
    }),
    [data, liveStreamData],
  );
  return <ChartCore option={options} notMerge empty={!data} style={{ height: 300 }} />;
};

export default Chart;
